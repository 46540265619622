import React, { useRef } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { ConfigurationManager } from '../../config';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import styles from './QRcomponent.module.scss';

// const fs = require('fs');
// const archiver = require('archiver');

const QRItem = ({ item, namePrefix = '' }) => {

    const downloadSVGHandler = () => {

        const elemString = svgQrRef.current.innerHTML
        console.log("downloadSVGHandler elem", JSZip)

        const filename = `${namePrefix} ${item.number}, ${item.name ? item.name : ''} ${item.sectionName ? (`Секция: ${item.sectionName}`) : ''}`
        const zip = new JSZip();
        zip.file(`${filename}.svg`, elemString);


        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                saveAs(content, "foodof_QR.zip");
            });

    }

    const { menuId } = useParams();
    const domain = ConfigurationManager.GetInstance().getItem("QR_MENU_BASE");
    const svgQrRef = useRef();



    return (
        <div key={item.id} className={styles.qr_item}>
            <>
                <span className={styles.table_title}>
                    {" "}
                    {namePrefix} {item.number}, {item.name} {item.sectionName && (`Секция: ${item.sectionName}`)}{" "}
                </span>
                <a
                    className={styles.qr_title}
                    href={`${domain}/showcase/${menuId}/?tableId=${item.id}&tableNumber=${item.number}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className={styles.qr} ref={svgQrRef} data={"qr"}>
                        <QRCode value={`${domain}/showcase/${menuId}/?tableId=${item.id}&tableNumber=${item.number}`} id={item.id} />
                    </div>
                </a>
                <button
                    className={styles.btn_download_qr}
                    onClick={(e) => downloadSVGHandler(item.id)}
                >
                    Скачать QR
                </button>
            </>
            <br />
        </div>

    );
};

export default QRItem;


