import classNames from 'classnames';
import React, { useCallback } from 'react';
import MenuStore from '../../../store/MenuStore';
import styles from '../forms.module.scss';


const Tables = ({
    menuFormData,
    allTables,
    setAllTables,
    setMenuFormData
}) => {
    const toggleTheTable = useCallback((tableId) => {
        let tables = JSON.parse(JSON.stringify(menuFormData.tables))
        for (let table of tables) {
            if (table.id === tableId) table.isChecked = !table.isChecked
        }
        setMenuFormData((state) => ({ ...state, tables }))
    }, [menuFormData.tables, setMenuFormData])

    const isTableChecked = useCallback((id) => {
        for (let table of menuFormData.tables) if (table.id === id && table.isChecked) return true
    }, [menuFormData.tables])

    const renderTables = () => {
        let areas = {};
        let tablesArr = []
        allTables?.forEach(table => areas[table.sectionId] = table.sectionName)

        for (let areaId in areas) {
            console.log("areaId", areaId)
            tablesArr.push(
                <div key={areaId} className={styles.table_section}>
                    <div className={styles.table_section_title}>
                        {areas[areaId]}
                    </div>
                    <div className={styles.table_section_tables}>
                        {menuFormData?.tables
                            .filter(table => table.sectionId === areaId)
                            ?.map((table, i) => {
                                return (
                                    <div
                                        className={classNames(styles.table_item, isTableChecked(table.id) ? styles.active : null)}
                                        key={table.id}
                                        onClick={(e) => {
                                            toggleTheTable(table.id);
                                        }}>
                                        {table.number}
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            )
        }
        return tablesArr;
    }

    const changeAllTablesActivity = (bool) => {
        let tables = menuFormData.tables
        for (let table of tables) table.isChecked = bool;
        setMenuFormData({ ...menuFormData, tables })
    }

    const checkTables = useCallback(() => {
        if (MenuStore.editableMenu[0].integrationId) {
            MenuStore.getTables(MenuStore.editableMenu[0].integrationId)
                .then(data => {
                    if (MenuStore.editableMenu[0]?.tables?.length > 0) {
                        MenuStore.editableMenu[0]?.tables.forEach(menuTable => {
                            for (let table of data) {
                                if (table.id === menuTable.id) table.isChecked = true
                            }
                        })
                    }
                    setAllTables(data);
                    setMenuFormData((state) => ({ ...state, tables: data }))
                })
        }
    }, [setAllTables, setMenuFormData])



    return (
        <>
            <div className={styles.tables_btn_wrapper}>
                <button className={styles.btn_allTables} type="button" onClick={() => changeAllTablesActivity(true)}> Включить все столы</button>
                <button className={styles.btn_allTables} type="button" onClick={() => changeAllTablesActivity(false)}> Выключить все столы</button>
            </div>

            <div className={styles.tables}>
                {renderTables()}
            </div>
        </>
    );
};

export default Tables;