import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import classnames from 'classnames';
import styles from '../ItemList.module.scss';

import Item from '../Item';
import ItemList from '../ItemList';
import AdminStore from '../../../store/AdminStore';

const GroupItem = ({
    items,
    item,
    config,
    parentId = null,
    onUpdateHandlerGroup = () => { },
    onUpdateHandlerProduct = () => { },
    onUpdateHandlerModificator = () => { },
    onDeleteHandler = () => { },
    showBtn,
}) => {
    const { id } = item;



    const [isGroupVisible, setIsGroupVisible] = useState(true);

    const hideGroupHandler = (e) => {
        setIsGroupVisible(!isGroupVisible)
        console.log("hideGroupHandler", isGroupVisible)
    }

    return (
        <div
            className={classnames(styles.group, { [styles.hidden]: !isGroupVisible, [styles.table]: AdminStore.tableView })}
            data-id={id}
            data-type={2}
        >

            <Item
                showBtn={showBtn}
                data={item}
                id={id}
                key={id}
                onUpdateHandler={onUpdateHandlerGroup}
                onDeleteHandler={onDeleteHandler}
            />

            {!AdminStore.tableView && item.images?.length > 0 &&
                <img className={styles.group_image} src={item.images?.[0]} alt="group"></img>
            }
            <div className={classnames(styles.list_wrapper, { [styles.table]: AdminStore.tableView })} >
                <ItemList
                    showBtn={showBtn}
                    items={items}
                    parentId={id}
                    onUpdateHandlerGroup={onUpdateHandlerGroup}
                    onUpdateHandlerProduct={onUpdateHandlerProduct}
                    onUpdateHandlerModificator={onUpdateHandlerModificator}
                    onDeleteHandler={onDeleteHandler}
                />
            </div>
            <button
                className={classnames(styles.hide_btn, { [styles.active]: isGroupVisible, [styles.table]: AdminStore.tableView })}
                onClick={(e) => hideGroupHandler(e)}
            >
                <div className={styles.hider}>
                    <i className="fas fa-sort"></i>
                </div>
            </button>
        </div>
    );
};

export default observer(GroupItem);