import { FC, useCallback, useState } from 'react';
import { kassa, taxationSystemLabels } from 'types';

import Form, { Label, SimpleItem } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import { RequiredRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import PopupStore from 'store/PopupStore';

import FormWrapper from '../FormWrapper';
import KassaStore from 'store/KassaStore';

// type kassaFormType = {
//     onSubmit: (arg: kassa) => Promise<any>;
// }

const KassaForm = ({ onSubmit }) => {

    const closeForm = () => {
        PopupStore.setActiveForm('');
    }

    //dev Extreme Form
    const initialFormData = KassaStore.activeKassa

    const [formData, setFormData] = useState(initialFormData)

    const onFieldDataChangedGroupForm = (e) => {
        setFormData(e.component.option("formData"));
    }

    const onSubmitHandler = () => {
        console.log("onSubmitHandler")
        onSubmit(formData).then(res => res.status === 202 && closeForm())
    }

    const onValueChangedTaxationSystem = useCallback((e) => {
        if (e.previousValue === e.value) return
        setFormData((state) => ({ ...state, taxationSystem: e.value }))
        console.log("onClick onValueChangedParentGroup")
    }, []);

    let taxationSystemOptions = taxationSystemLabels.map((item, i) => ({ label: item, value: i }))

    return (
        <FormWrapper title={"Виртуальная касса"}>

            <form /* onSubmit={handleSubmit} */>
                <Form formData={formData} labelLocation="top" onFieldDataChanged={onFieldDataChangedGroupForm} >
                    <SimpleItem dataField="name" editorType="dxTextBox">
                        <Label text="Название кассы" />
                        <RequiredRule message="Обязательно к заполнению" />
                    </SimpleItem>
                    <SimpleItem dataField="Inn" editorType="dxTextBox">
                        <Label text="ИНН" />
                        <RequiredRule message="Обязательно к заполнению" />
                    </SimpleItem>
                    <SimpleItem dataField="HashPassword" editorType="dxTextBox">
                        <Label text="Пароль платежной системы" />
                        <RequiredRule message="Обязательно к заполнению" />
                    </SimpleItem>
                    <SimpleItem dataField="PublicId" editorType="dxTextBox">
                        <Label text="Id платежной системы" />
                        <RequiredRule message="Обязательно к заполнению" />
                    </SimpleItem>
                    <SimpleItem render={() => (
                        <SelectBox
                            dataSource={taxationSystemOptions}
                            // editorType="dxSelectBox"
                            // editorOptions={{ items: parentGroupOptions }}
                            displayExpr="label"
                            valueExpr="value"
                            onValueChanged={onValueChangedTaxationSystem}
                            defaultValue={formData.taxationSystem}
                        />
                    )}>
                        <Label text="Система налогообложения" />
                        <RequiredRule message="Обязательно к заполнению" />
                    </SimpleItem>
                    <SimpleItem dataField="isFiscal" editorType="dxCheckBox">
                        <Label text="Фискализация" />
                    </SimpleItem>
                </Form>
            </form>

            <Button
                text="Сохранить"
                stylingMode={"contained"}
                type="success"
                icon="floppy"
                onClick={onSubmitHandler}
            />

        </FormWrapper>);
};

export default KassaForm;