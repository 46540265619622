import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import classnames from 'classnames';
import styles from './ItemList.module.scss';

import Item from './Item';
import GroupItem from './GroupItem/';
import AdminStore from '../../store/AdminStore';


const ItemList = ({
  showModifires = true,
  items = [],
  config,
  parentId = null,
  onUpdateHandlerGroup = () => { },
  onUpdateHandlerProduct = () => { },
  onUpdateHandlerModificator = () => { },
  onDeleteHandler = () => { },
  showBtn = true,
}) => {

  return (
    <>
      {items
        .filter(item => item.parent === parentId)
        .sort((a, b) => a.index > b.index)
        .sort((a, b) => a.type > b.type)
        .map((item) => {
          const { id, type } = item;
          switch (type) {
            case 1:
              return <div key={id} data-id={id} className={classnames(styles.product, { [styles.table]: AdminStore.tableView })} >
                <Item
                  showBtn={showBtn}
                  data={item}
                  id={id}
                  key={id}
                  onUpdateHandler={onUpdateHandlerProduct}
                  onDeleteHandler={onDeleteHandler}
                />
              </div>
            case 2:
              return <GroupItem
                key={id}
                items={items}
                item={item}
                config={config}
                parentId={parentId}
                onUpdateHandlerGroup={onUpdateHandlerGroup}
                onUpdateHandlerProduct={onUpdateHandlerProduct}
                onUpdateHandlerModificator={onUpdateHandlerModificator}
                onDeleteHandler={onDeleteHandler}
                showBtn={showBtn}
              />
            case 3:
              // if (!showModifires) return null;
              return <div key={id} data-id={id} className={classnames(styles.product, { [styles.table]: AdminStore.tableView })} >
                <Item
                  type={3}
                  showBtn={showBtn}
                  data={item}
                  id={id}
                  key={id}
                  onUpdateHandler={onUpdateHandlerModificator}
                  onDeleteHandler={onDeleteHandler}
                />
              </div>
            case 4:
              return <GroupItem
                key={id}
                items={items}
                item={item}
                config={config}
                parentId={parentId}
                onUpdateHandlerGroup={onUpdateHandlerGroup}
                onUpdateHandlerProduct={onUpdateHandlerProduct}
                onUpdateHandlerModificator={onUpdateHandlerModificator}
                onDeleteHandler={onDeleteHandler}
                showBtn={showBtn}
              />
            default:
              return null;
          }

        })}
    </>
  );
};

export default observer(ItemList);