import React from 'react';
import Spinner from '../exeptions/Spinner';

import IntegrationItem from './IntegrationItem';

import styles from './IntegrationList.module.scss';

const IntegrationList = ({
    items = [],
    onUpdateHandler,
    onDeleteHandler,
    isLoading
}) => {

    if (isLoading) return <Spinner />
    return (
        <div className={styles.list}>
            {items.map(item => {
                return (
                    <IntegrationItem
                        item={item}
                        key={item.id}
                        onUpdateHandler={onUpdateHandler}
                        onDeleteHandler={onDeleteHandler}
                    />
                )
            })}
        </div>
    );
};

export default IntegrationList;