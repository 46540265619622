import { IIntegration, IPaymentSystem } from "types";

export const defaultPaymentSystem: IPaymentSystem = {
  id: '',
  name: '',
  isFiscal: null,
}

export const defaultIntegration: IIntegration = {
  type: 0,
  name: '',
  login: '',
  password: '',
  hostPort: '',
  paymentSystem: defaultPaymentSystem,
  terminalGroup: '',
}