import { makeAutoObservable } from "mobx";

class PopupStore {
    isIntegrPopupOpen = false;
    createElemPopup = false;

    activeForm = '';

    activeFormData = {
        formType: '',
        formData: {}
    }

    setActiveForm(string) {
        this.activeForm = string;
    }

    constructor() {
        makeAutoObservable(this);
    }

    setValue(e, item) {
        item.value = e.target.value;
    }

    setActiveFormData(activeFormData) {
        this.activeFormData = activeFormData;
    }

    resetForm(config) {
        for (let textField of config.textFields) {
            textField.value = '';
        }
    }

    toggleCreateElemPopup() {
        this.createElemPopup = !this.createElemPopup;
    }
}

export default new PopupStore();