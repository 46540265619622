import { kassa } from 'types';
import { makeAutoObservable } from 'mobx';

import { CloudPayConnectionManager } from '../http/axios';

import MsgStore from './MsgStore';

class KassaStore {
    kassaList: kassa[];

    activeKassa: kassa;

    constructor() {
        makeAutoObservable(this);
    }

    setKassaList(kassaList: kassa[]) {
        this.kassaList = kassaList;
    }

    setActiveKassa(id: string) {
        this.activeKassa = this.kassaList.filter(item => item.id === id)?.[0];
    }

    resetActiveKassa() {
        this.activeKassa = { name: '', isFiscal: null }
    }

    async fetchKassaList(): Promise<kassa[]> {
        try {
            const res = await CloudPayConnectionManager.GetInstance().GetClient().get("/kkt");
            this.setKassaList(res.data)
            return res
        } catch (error) {
            MsgStore.showMsg({ value: error.response.data.title, status: "error" }, 6000);
            console.error("ошибка fetchKassaList", error.message)
        }
    }

    async createKassa(params: kassa): Promise<any> {
        try {
            const res = await CloudPayConnectionManager.GetInstance().GetClient().post("/kkt", params);
            await this.fetchKassaList();
            return res;
        } catch (error) {
            MsgStore.showMsg({ value: error.response.data.title, status: "error" }, 6000);
            console.error("ошибка createKassa", error.message)
        }
    }

    async updateKassa(params: kassa): Promise<any> {
        try {
            const res = await CloudPayConnectionManager.GetInstance().GetClient().post("/kkt/update", params);
            return res;
        } catch (error) {
            MsgStore.showMsg({ value: error.response.data.title, status: "error" }, 6000);
            console.error("ошибка updateKassa", error.message)
        }
    }

    async deleteKassa(id: string): Promise<any> {
        try {
            const res = await CloudPayConnectionManager.GetInstance().GetClient().delete(`/kkt/${id}`);
            this.kassaList = ([...this.kassaList.filter(item => item.id !== id)])

            return res
        } catch (error) {
            MsgStore.showMsg({ value: error.response.data.title, status: "error" }, 6000);
            console.error("ошибка deleteKassa", error.message)
        }
    }
}

export default new KassaStore();