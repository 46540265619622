import { makeAutoObservable } from "mobx"
import { ConnectionManager } from "../http/axios";
import AdminStore from "./AdminStore";
import MenuStore from "./MenuStore";
import MsgStore from "./MsgStore";

class OrderStore {

    orderList = [];

    constructor() {
        makeAutoObservable(this);
    }

    setOrderList(orderList) {
        this.orderList = orderList
    }

    async fetchOrderList() {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().get("/order");
            this.setOrderList(res.data)

            MsgStore.showMsg({ value: "Список заказов загружен", status: "ok" });
            return res;
        } catch (error) {
            console.error("ошибка fetchOrderList", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });
        }
    }

    get orderListWithData() {
        let orderList = JSON.parse(JSON.stringify(this.orderList));

        for (let order of orderList) {
            order.currentMenuName = MenuStore.menuNamesDictionary[order.currentMenu]
            order.restaurantName = AdminStore.integrationNamesDictionary[order.restaurant]
        }

        return orderList
    }

}

export default new OrderStore();