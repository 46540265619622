import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import styles from './MenuPage.module.scss';

import AdminStore from '../../store/AdminStore';
import CatalogStore from '../../store/CatalogStore';
import PopupStore from '../../store/PopupStore';
import MenuStore from '../../store/MenuStore';

import HaveNoMenu from '../../components/exeptions/HaveNoMenu';
import Spinner from '../../components/exeptions/Spinner';

import Catalog from '../../components/Catalog';
import Showcase from '../../components/Showcase';

import Page from '../../Layout/Page.tsx'

const MenuPage = () => {
    const { menuId } = useParams();

    useEffect(() => {
        console.log("MenuStore.setEditableMenu menuId", menuId)
        if (menuId === "newMenu") {
            console.log("newMenu")
            PopupStore.setActiveForm("newMenu");
            MenuStore.createNewEditableMenu();
        } else {
            MenuStore.fetchShowcase().then(data => {
                console.log("Menu")
                MenuStore.setEditableMenu(MenuStore.showcase.filter(menu => menu.id === menuId))
                CatalogStore.fetchCatalog(MenuStore.editableMenu?.[0]?.integrationId);
            })
        }
    }, [menuId])


    const onSaveChangeInShowcaseHandler = () => {
        console.debug("onSaveChangeInShowcaseHandler");

        MenuStore.updateShowcaseMenu();
    }

    const onChangeMenu = (e) => {
        console.debug("onChangeMenu")
        PopupStore.setActiveForm("menu");
    }

    const switchTableViewHandler = () => AdminStore.setTableView(!AdminStore.tableView)


    console.log('MenuStore.editableMenu', MenuStore.editableMenu)

    let buttons = [
        { text: "Сохранить", onClick: () => onSaveChangeInShowcaseHandler(), icon: "far fa-save", },
        { text: "QR-код", onClick: () => PopupStore.setActiveForm("qr"), icon: "fas fa-qrcode", },
        { text: "Обновить меню", onClick: () => MenuStore.refreshDataFromIntegration(), icon: "fas fa-redo", },
        { text: "Табличный вид", onClick: switchTableViewHandler, icon: "fas fa-table", },
        { text: "Редактировать", onClick: (e) => onChangeMenu(e), icon: "fas fa-pencil-alt", },
        { text: "Удалить", onClick: () => MenuStore.deleteMenu(menuId), icon: "fas fa-trash-alt", },
    ]

    if (menuId === "newMenu" || !MenuStore.editableMenu?.[0]?.name) buttons = [
        {
            text: "Создать меню",
            onClick: () => { PopupStore.setActiveForm("newMenu"); MenuStore.createNewEditableMenu(); },
            icon: "far fa-save",
        },
    ]

    return (
        <Page buttons={buttons}>
            <div className={styles.content_wrapper}>
                {!MenuStore.editableMenu?.[0]?.name || menuId === 'newMenu' ?
                    <HaveNoMenu />
                    :
                    <>
                        <div className={styles.content_window}>
                            {AdminStore.catalogIsLoading ? <Spinner /> : <Catalog showBtn={false} />}
                        </div>
                        <div className={styles.content_window}>
                            {AdminStore.showcaseIsLoading ? <Spinner /> : <Showcase />}
                        </div>
                    </>
                }
            </div>
        </Page>
    );
};

export default observer(MenuPage);