import React from 'react';
import { observer } from 'mobx-react-lite';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";

import classnames from 'classnames';

import CatalogStore from '../../../store/CatalogStore';
import MenuStore from '../../../store/MenuStore';
import PopupStore from '../../../store/PopupStore';

import CustomButton from '../formComponents/CustomButton'

import FormWrapper from '../FormWrapper'

import CustomField from '../formComponents/CustomField';
import ParentGroupDropBox from '../formComponents/ParentGroupDropBox';
import styles from '../forms.module.scss';

const ModificatorForm = ({
    onSubmit = () => console.log("ModificatorForm onSubmit"),
    mode = "catalog"
}) => {

    // const active = PopupStore.createElemPopup
    // let showPopup = styles.popup_container
    // if (active) showPopup = `${styles.popup_container} ${styles.active}`

    const closeForm = () => {
        // PopupStore.toggleCreateElemPopup();
        PopupStore.setActiveForm('');
    }

    console.debug("CatalogStore.catalogActiveItem", CatalogStore.catalogActiveItem)



    return (
        <Formik

            initialValues={{
                ...CatalogStore.catalogActiveItem,
            }}
            // onSubmit={async values => {
            //     console.log("onSubmit", values)
            //     onSubmit(values);
            //     closeForm();
            // }}

            validationSchema={Yup.object().shape({
                name: Yup.string().trim().required("Обязательно к заполнению"),
                price: Yup.number().typeError("Введите число").required("Обязательно к заполнению").positive("Цена не может быть отрицательной"),
                // index: Yup.number().typeError("Введите число").required("Обязательно к заполнению").integer("Должно быть целое число").min(0),
                // description: Yup.string()
                //     .required("Обязательно к заполнению"),
                // images: Yup.string()
                //     .required("Обязательно к заполнению"),
            })}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    // handleChange,
                    // handleBlur,
                    // handleSubmit,
                    handleReset
                } = props;

                const onSubmitHandler = (values) => {
                    console.log("onSubmit", values)
                    onSubmit(values).then(res => res.status === 202 && closeForm());
                    // closeForm();
                }

                return (

                    <FormWrapper title={'Модификатор для продукта'}>
                        <Form>
                            <div className={styles.form_fields}>

                                <CustomField customFieldProps={props} name="name" label="Название модификатора" isRequired={true} />
                                <CustomField customFieldProps={props} name="description" label="Описание" />
                                <CustomField customFieldProps={props} name="price" label="Цена" type="number" />

                                {mode === "showcase" &&
                                    <CustomField customFieldProps={props} name="index" label="Индекс модификатора" />
                                }

                                <CustomField customFieldProps={props} name="images[0]" label="Изображение для модификатора" />
                                <CustomField customFieldProps={props} name="currency" label="Валюта для расчета" />

                                <ParentGroupDropBox mode={mode} />

                            </div>

                        </Form>

                        <CustomButton
                            onClick={() => onSubmitHandler(values)}
                            disabled={isSubmitting}
                            icon={"dx-icon dx-icon-floppy"}
                            text={"Сохранить"}
                        />

                    </FormWrapper>

                );
            }}
        </Formik>)
};

export default observer(ModificatorForm);





