import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import PopupStore from '../../store/PopupStore';

import styles from './KassaPage.module.scss';
import Page from '../../Layout/Page';
import KassaStore from '../../store/KassaStore';
import KassaList from './KassaList';

import Test from './test.js'

const KassaPage = () => {

    const [IsLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        KassaStore.fetchKassaList().finally(() => setIsLoading(false));
    }, [])

    const onCreateHandler = () => {
        console.debug("onCreateHandler");

        KassaStore.resetActiveKassa();
        PopupStore.setActiveForm("newKassa");
    }

    const buttons = [{ text: "Добавить Кассу", onClick: onCreateHandler, icon: "fas fa-plus" }]

    return (
        <Page buttons={buttons}>
            <div className={styles.list_wrapper}>
                <div className={styles.content_title}>
                    Виртуальные кассы
                </div>
                {/* <Test/> */}
                <KassaList items={KassaStore.kassaList} />
            </div>
        </Page>
    );
};

export default observer(KassaPage);




