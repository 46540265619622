import React from 'react';
import QRItem from './QRItem';

import styles from './QRcomponent.module.scss';

const QRList = ({ tables, namePrefix = '', noFoundMessage = '' }) => {
    return (
        tables?.length > 0 ?
            tables?.map((item) => (
                <QRItem key={item.id} item={item} namePrefix={namePrefix} />
            ))
            :
            <div>
                <span className={styles.table_title}>
                    {noFoundMessage}
                </span>
            </div>
    );
};

export default QRList;