import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import moment from 'moment'

import classnames from 'classnames';

import PopupStore from '../../../store/PopupStore';
import MenuStore from '../../../store/MenuStore';
import AdminStore from '../../../store/AdminStore';
import KassaStore from '../../../store/KassaStore';


import SelectBox from 'devextreme-react/select-box';
import { TagBox } from 'devextreme-react/tag-box';

import TabPanel, { Item } from "devextreme-react/tab-panel";

import Form, { Label, SimpleItem } from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';

import styles from '../forms.module.scss';
import DxHoursOfWork from './DxHoursOfWork';
import FormWrapper from '../FormWrapper';
import QueryRulesList from './QueryRulesList/QueryRulesList';
import Tables from './Tables';

import { fields } from './QueryRulesList/QueryRules/QueryConfig';

const MenuForm = ({
    onSubmit,
    mode = "menu"
}) => {

    const [allTables, setAllTables] = useState([]);
    const [loading, setIsLoading] = useState(false)
    const [orderTypes, setOrderTypes] = useState([]);
    // const [, setRender] = useState({});

    const onSubmitHandler = () => {
        const hoursOfWork = prepareHoursOfWork(menuFormData.hoursOfWork);
        const availablePayments = prepareAvailablePayments(menuFormData.formData_availablePayments);
        const addons = prepareAddons(MenuStore.editableMenu?.[0]?.addons)

        onSubmit({ ...menuFormData, hoursOfWork, availablePayments, addons }).then(res => res.status === 202 && closeForm());
        console.debug("menuFormData submitBtn", { ...menuFormData, hoursOfWork, availablePayments });
    }

    const prepareHoursOfWork = (data) => {
        let hoursOfWork = JSON.parse(JSON.stringify(data));

        const time = (date) => {
            if (date.length < 9) return date
            let res = moment(date).format('HH:mm:ss')
            console.log("prepareHoursOfWork", res)
            // return res 
            return res
        }

        for (let item of hoursOfWork) {
            delete item.__KEY__
            item.opening = time(item.opening)
            item.closure = time(item.closure)
        }
        console.log("hoursOfWork function", hoursOfWork)
        return hoursOfWork
    }

    const prepareAvailablePayments = (data) => {
        let availablePayments = JSON.parse(JSON.stringify(data));
        let res = []
        for (let item of availablePayments) {
            console.log("prepareAvailablePayments", item)
            if (["Card", "Cash", "CloudPay"].includes(item)) {
                res.push({ name: item })
            } else {
                let elem = KassaStore.kassaList?.find(elem => elem.id === item)
                if (elem) {
                    const { name, id, isFiscal } = elem
                    res.push({ name, id, isFiscal })
                }
            }
        }
        return res
    }

    const prepareAddons = (data) => {

        let addons = JSON.parse(JSON.stringify(data));
        console.log(" addons", addons)
        console.log("fields", fields)

        addons.forEach(addon =>
            addon.condition?.rules.forEach(rule =>
                fields.forEach(field => {
                    if (rule.field === field?.name) {
                        rule.type = field?.dataType;
                        if (!Array.isArray(rule.value)) rule.value = [rule.value]
                        console.log("field", field)
                    }
                })
            )
        )

        console.log("prepareAddons", addons)

        return addons
    }

    // DX Form State
    const [menuFormData, setMenuFormData] = useState({
        ...MenuStore.editableMenu[0],
        tables: allTables,
        type: MenuStore.editableMenu[0]?.type,
        formData_availablePayments: MenuStore.editableMenu[0]?.availablePayments ?
            MenuStore.editableMenu[0]?.availablePayments?.map(item => {
                if (["Card", "Cash", "CloudPay"].includes(item.name)) {
                    return item.name
                } else return item.id
            }) : []
    });

    const onFieldDataChangeMenuForm = (e) => {
        console.log("onFieldDataChangeRestorePassword event", e)
        setMenuFormData(e.component.option("formData"))
    }

    //получение типов оплаты
    const getOrderTypesHandler = useCallback((integrationId, menuType) => {
        if (integrationId && +menuFormData.integrationType === 2 && +menuType === 2) {
            AdminStore.getOrderTypes(integrationId).then(data => setOrderTypes(data))
        }
    }, [menuFormData.integrationType]);

    //DX SelectBox MenuType
    const getMenuTypeOptions = () => {
        let menuOptions = [
            { label: "Витрина", value: 0 },
            { label: "Table-сервис", value: 1 },
            { label: "Доставка", value: 2 },
            { label: "Расходные накладные", value: 3 },
            { label: "Приходные накладные", value: 4 },
        ]

        switch (menuFormData.integrationType) {
            case 0: menuOptions = [menuOptions[0]]; break;
            case 1: menuOptions = [menuOptions[0], menuOptions[2]]; break;
            case 2: menuOptions = [menuOptions[0], menuOptions[1], menuOptions[2]]; break;
            case 3: menuOptions = [menuOptions[3], menuOptions[4]]; break;
            default: break;
        }

        return menuOptions
    }



    const onValueChangedMenuType = useCallback((e) => {
        if (e.previousValue === e.value) return
        setMenuFormData((state) => ({ ...state, type: e.value }))
        getOrderTypesHandler(menuFormData.integrationId, e.value);
    }, [getOrderTypesHandler, menuFormData.integrationId]);

    //DX SelectBox IntegrationType
    const getIntegrationOptions = () => {
        return integrations?.map(item => ({ ...item, label: item.name, value: item.id }))
    }

    const onValueChangedIntegration = useCallback((e) => {
        const defaultMenuType = () => {
            switch (menuFormData.integrationType) {
                case 0: return 0;
                case 1: return 0;
                case 2: return 0;
                case 3: return 3;
                default: break;
            }
        }

        if (e.previousValue === e.value) return
        const integrationType = AdminStore.integrationsList.filter(item => item.id === e.value)?.[0]?.type
        setMenuFormData((state) => (
            {
                ...state,
                integrationId: e.value,
                integrationType,
                formData_availablePayments: [],
                orderType: {},
                type: defaultMenuType(),
            }));
        // setOrderTypes([]);
        console.log("onValueChangedIntegration e", e)
        getOrderTypesHandler(e.value, menuFormData.type);

    }, [getOrderTypesHandler, menuFormData.integrationType, menuFormData.type]);

    //DX /SelectBox IntegrationType
    // const defaultPaymentTypeOptions = menuFormData.availablePayments.map(item => item.id)

    const kassaOptions = KassaStore?.kassaList?.map(item => ({ label: item.name, value: item.id })) ?? []
    // const kassaOptions = [];



    const paymentTypeOptions = [
        { label: "Карта", value: "Card" },
        { label: "Наличные", value: "Cash" },
        ...kassaOptions
    ]


    const onValueChangedPaymentType = useCallback((e) => {
        if (e.previousValue === e.value) return
        setMenuFormData((state) => ({ ...state, formData_availablePayments: e.value }));
    }, []);

    //DX OrderTypes
    const orderTypesOptions = () => {
        if (orderTypes) {
            return orderTypes?.map(item => { return { label: item.name, value: item.id } })
        }
    }



    const onValueChangedOrderType = useCallback((e) => {
        if (e.previousValue === e.value) return
        const orderTypeItem = orderTypes?.find(item => item.id === e.value)
        setMenuFormData((state) => ({ ...state, orderType: orderTypeItem }));
    }, [orderTypes]);


    //столы
    const toggleTheTable = useCallback((tableId) => {
        let tables = JSON.parse(JSON.stringify(menuFormData.tables))
        for (let table of tables) {
            if (table.id === tableId) table.isChecked = !table.isChecked
        }
        setMenuFormData((state) => ({ ...state, tables }))
    }, [menuFormData.tables])

    const isTableChecked = useCallback((id) => {
        for (let table of menuFormData.tables) if (table.id === id && table.isChecked) return true
    }, [menuFormData.tables])

    const renderTables = () => {
        let areas = {};
        let tablesArr = []
        allTables?.forEach(table => areas[table.sectionId] = table.sectionName)

        for (let areaId in areas) {
            console.log("areaId", areaId)
            tablesArr.push(
                <div key={areaId} className={styles.table_section}>
                    <div className={styles.table_section_title}>
                        {areas[areaId]}
                    </div>
                    <div className={styles.table_section_tables}>
                        {menuFormData?.tables
                            .filter(table => table.sectionId === areaId)
                            ?.map((table, i) => {
                                return (
                                    <div
                                        className={classnames(styles.table_item, isTableChecked(table.id) ? styles.active : null)}
                                        key={table.id}
                                        onClick={(e) => {
                                            toggleTheTable(table.id);
                                        }}>
                                        {table.number}
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            )
        }
        return tablesArr;
    }

    const changeAllTablesActivity = (bool) => {
        let tables = menuFormData.tables
        for (let table of tables) table.isChecked = bool;
        setMenuFormData({ ...menuFormData, tables })
    }

    const checkTables = useCallback(() => {
        if (MenuStore.editableMenu[0].integrationId) {
            MenuStore.getTables(MenuStore.editableMenu[0].integrationId)
                .then(data => {
                    if (MenuStore.editableMenu[0]?.tables?.length > 0) {
                        MenuStore.editableMenu[0]?.tables.forEach(menuTable => {
                            for (let table of data) {
                                if (table.id === menuTable.id) table.isChecked = true
                            }
                        })
                    }
                    setAllTables(data);
                    setMenuFormData((state) => ({ ...state, tables: data }))
                })
        }
    }, [])

    // / столы


    //склады
    const renderStorage = useCallback(() => {
        return (
            <div className={styles.storage_section}>
                {allTables
                    ?.map((table, i) => {
                        return (
                            <div
                                className={classnames(styles.storage_item, isTableChecked(table.id) ? styles.active : null)}
                                key={table.id}
                                onClick={(e) => {
                                    toggleTheTable(table.id);
                                }}>
                                {table.name}
                            </div>
                        )
                    })
                }
            </div>
        )
    }, [allTables, isTableChecked, toggleTheTable])


    //получение интеграций
    useEffect(() => {
        AdminStore.fetchPureIntegrationsList();
        KassaStore.fetchKassaList();
    }, [])

    useEffect(() => {
        checkTables();
    }, [checkTables])

    useEffect(() => {
        getOrderTypesHandler(menuFormData.integrationId, menuFormData.type);
    }, [getOrderTypesHandler, menuFormData.integrationId, menuFormData.type])


    const integrations = AdminStore.integrationsList;

    const closeForm = () => PopupStore.setActiveForm('');

    console.log("DX menuFormData", menuFormData)
    // console.log(allTables)


    return (
        <FormWrapper title={"Меню"}>

            <TabPanel focusStateEnabled={false}>
                <Item title="Меню" icon="food">
                    <Form formData={menuFormData} labelLocation="top" onFieldDataChanged={onFieldDataChangeMenuForm}>
                        <SimpleItem dataField="name" editorType="dxTextBox">
                            <Label text="Название" />
                            <RequiredRule message="Обязательно к заполнению" />
                        </SimpleItem>
                        <SimpleItem dataField="description" editorType="dxTextBox">
                            <Label text="Описание" />
                        </SimpleItem>
                        <SimpleItem dataField="banner" editorType="dxTextBox">
                            <Label text="Ссылка на изображение" />
                        </SimpleItem>

                        {mode === "newMenu" &&
                            <SimpleItem render={() => (
                                <SelectBox
                                    dataSource={getIntegrationOptions()}
                                    editorType="dxSelectBox"
                                    editorOptions={{ items: getIntegrationOptions() }}
                                    displayExpr="label"
                                    valueExpr="value"
                                    onValueChanged={onValueChangedIntegration}
                                    defaultValue={menuFormData.integrationId}
                                />
                            )}
                            >
                                <Label text="Выберете ресторан" />
                            </SimpleItem>
                        }

                        <SimpleItem dataField="published" editorType="dxCheckBox">
                            <Label text="Опубликовать меню" />
                        </SimpleItem>
                        <SimpleItem render={() => (
                            <SelectBox
                                dataSource={getMenuTypeOptions()}
                                editorType="dxSelectBox"
                                editorOptions={{ items: getMenuTypeOptions() }}
                                displayExpr="label"
                                valueExpr="value"
                                onValueChanged={onValueChangedMenuType}
                                defaultValue={menuFormData.type}
                            />
                        )}
                        >
                            <Label text="Режим работы меню" />
                        </SimpleItem>

                        {[0].includes(menuFormData.type) &&
                            <SimpleItem dataField="isShowCart" editorType="dxCheckBox">
                                <Label text="Показывать корзину" />
                            </SimpleItem>
                        }

                        {[2, 3, 4].includes(menuFormData.type) &&
                            <SimpleItem dataField="canOrderByTime" editorType="dxCheckBox">
                                <Label text="Разрешить заказ ко времени" />
                            </SimpleItem>
                        }

                        {[1, 2].includes(menuFormData.type) &&
                            <SimpleItem render={() =>
                                <TagBox
                                    dataSource={paymentTypeOptions}
                                    editorType="dxSelectBox"
                                    editorOptions={{ items: paymentTypeOptions }}
                                    displayExpr="label"
                                    valueExpr="value"
                                    onValueChanged={onValueChangedPaymentType}
                                    defaultValue={menuFormData.formData_availablePayments}
                                />
                            }
                            >
                                <Label text="Доступные способы оплаты" />
                            </SimpleItem>
                        }

                        {
                            [2].includes(menuFormData.type) &&
                            [2].includes(menuFormData.integrationType) &&
                            // orderTypes?.length > 0 &&

                            <SimpleItem render={() => (
                                <SelectBox
                                    dataSource={orderTypesOptions()}
                                    editorType="dxSelectBox"
                                    editorOptions={{ items: orderTypesOptions() }}
                                    displayExpr="label"
                                    valueExpr="value"
                                    onValueChanged={onValueChangedOrderType}
                                    defaultValue={menuFormData.orderType.id}
                                />
                            )}
                            >
                                <Label text="Тип заказа" />
                            </SimpleItem>
                        }

                        {[3, 4].includes(menuFormData.integrationType) &&
                            <SimpleItem dataField="minimalOrderPrice" editorType="dxTextBox">
                                <Label text="Минимальная сумма заказа" />
                            </SimpleItem>
                        }


                        {/* <ButtonItem
                            buttonOptions={{ ...saveMenuButtonOptions, stylingMode: loading ? "outlined" : "contained" }}
                            horizontalAlignment={"left"}
                        /> */}
                    </Form>
                </Item>

                {[2].includes(menuFormData.integrationType) &&
                    menuFormData.type === 1 &&
                    <Item title="Столы" icon="user">

                        {/* <Tables
                        menuFormData={menuFormData}
                        allTables={allTables}
                        setMenuFormData={setMenuFormData}
                        setAllTables={setAllTables}
                        /> */}
                        {/* <>
                            <div className={styles.tables_btn_wrapper}>
                                <button className={styles.btn_allTables} type="button" onClick={() => changeAllTablesActivity(true)}> Включить все столы</button>
                                <button className={styles.btn_allTables} type="button" onClick={() => changeAllTablesActivity(false)}> Выключить все столы</button>
                            </div>

                            <div className={styles.tables}>
                                {renderTables()}
                            </div>
                        </> */}

                        <Tables
                            menuFormData={menuFormData}
                            allTables={allTables}
                            setAllTables={setAllTables}
                            setMenuFormData={setMenuFormData}
                        />
                    </Item>
                }

                {[3, 4].includes(menuFormData.integrationType) &&
                    <Item title="Склады" icon="product" /* badge="new" */>
                        <>
                            <div className={styles.tables_btn_wrapper}>
                                <button className={styles.btn_allTables} type="button" onClick={() => changeAllTablesActivity(true)}> Включить все склады</button>
                                <button className={styles.btn_allTables} type="button" onClick={() => changeAllTablesActivity(false)}> Выключить все склады</button>
                            </div>

                            <div className={styles.tables}>
                                {renderStorage()}
                            </div>
                        </>

                        {/* <Tables
                            menuFormData={menuFormData}
                            allTables={allTables}
                            setAllTables={setAllTables}
                            setMenuFormData={setMenuFormData}
                        /> */}
                    </Item>
                }

                {![0].includes(menuFormData.type) &&
                    <Item title="Аддоны" icon="selectall" /* badge="new" */>
                        <Button
                            text="+Аддон"
                            stylingMode={"contained"}
                            type="success"
                            icon="floppy"
                            onClick={() => MenuStore.createAddon()}
                        />
                        <QueryRulesList />
                    </Item>
                }

                <Item title="Время работы" icon="clock" /* badge="new" */>
                    <DxHoursOfWork hoursOfWork={menuFormData.hoursOfWork} />
                </Item>

            </TabPanel>

            <Button
                text="Сохранить"
                stylingMode={loading ? "outlined" : "contained"}
                type="success"
                icon="floppy"
                onClick={onSubmitHandler}
            />
        </FormWrapper>
    )
};

export default observer(MenuForm);

