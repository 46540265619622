import React from 'react';

import DataGrid, {
    Selection,
    Column,
    Grouping,
    Pager,
    Paging,
    SearchPanel,
} from 'devextreme-react/data-grid';

import { Scrolling } from 'devextreme-react/data-grid';
import PopupStore from '../../store/PopupStore';



const OrderList = ({ children, dataSource }) => {

    const onSelectionChangedHandler = (e) => {
        console.log("DataGrid onSelectionChanged", e)
        
        PopupStore.setActiveForm("orderDetails");
        PopupStore.setActiveFormData({ formType: 'orderDetails', formData: e.data})
    }

    return (

        <DataGrid
            dataSource={dataSource}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}

            height={"85vh"}

            // onSelectionChanged={(e) => { onSelectionChangedHandler(e) }}
            // onClick={(e) => {console.log("DataGrid onClick", e)}}
            onRowClick={(e) => {onSelectionChangedHandler(e)}}
        // onContentReady={this.onContentReady}
        >
            <Selection mode="single" />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Grouping autoExpandAll={false} />

            <Column dataField="amount" caption="Цена" width={100} />

            <Column dataField="details.sum" caption="Сумма" width={100} />
            <Column dataField="details.status" caption="Статус" width={100} />

            <Column dataField="number" caption="Номер" width={100} />
            <Column dataField="paymentType.name" caption="Тип оплаты" width={100} />
            <Column dataField="dateTime" caption="Время" dataType="datetime" width={180} />
            <Column dataField="status" caption="Статус" width={100} />
            {/* <Column dataField="currentMenu" caption="Id Меню" /> */}
            <Column dataField="currentMenuName" caption="Меню" />
            {/* <Column dataField="restaurant" caption="Id ресторана" /> */}
            <Column dataField="restaurantName" caption="Ресторан" />

            {/* <Column dataField="canOrderByTime " caption="Заказ на дату" /> */}
            <Column dataField="completeBefore" caption="Заказ на дату" />

            {children}

            <Scrolling mode="virtual" />
            {/* 
            <Pager allowedPageSizes={10} showPageSizeSelector={true} />
            <Paging defaultPageSize={5} />
             */}
        </DataGrid>

    );
};

export default OrderList;