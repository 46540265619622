import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import PopupStore from '../../../store/PopupStore';
import CatalogStore from '../../../store/CatalogStore';
import MenuStore from '../../../store/MenuStore';
import AdminStore from '../../../store/AdminStore';
import KassaStore from '../../../store/KassaStore';


import GroupForm from '../../forms/GroupForm';
import ProductForm from '../../forms/ProductForm';
import IntegrationForm from '../../forms/IntegrationForm';

import KassaForm from '../../forms/KassaForm';
// import MenuForm from '../../forms/MenuForm/';

import QRcomponent from '../../QRcomponent/QRcomponent';
import ModificatorForm from '../ModificatorForm/ModificatorForm';
import AuthPage from '../../../pages/AuthPage';
import AuthForm from '../../AuthForm';
import GroupModificatorForm from '../GroupModificatorForm/GroupModificatorForm';
import MenuForm from '../MenuForm';
import OrderDetails from '../OrderDetails/OrderDetails';
// import CafeForm from '../CafeForm/CafeForm';

function ShowForm() {
    const navigate = useNavigate();

    const showForm = () => {
        switch (PopupStore.activeForm) {
            case "group":
                return <GroupForm
                    onSubmit={CatalogStore.updateCatalogItem}
                />
            case "newGroup":
                return <GroupForm
                    onSubmit={CatalogStore.createGroup}
                />
            case "showcaseGroup":
                return <GroupForm
                    mode={"showcase"}
                    onSubmit={(item) => { MenuStore.updateItemInEditableMenu(item); PopupStore.setActiveForm(''); }}
                />
            case "product":
                return <ProductForm
                    mode={"catalog"}
                    onSubmit={(item) => CatalogStore.updateCatalogItem(item)}
                />
            case "newProduct":
                return <ProductForm
                    mode={"catalog"}
                    onSubmit={CatalogStore.createProduct}
                />
            case "showcaseProduct":
                return <ProductForm
                    mode={"showcase"}
                    onSubmit={(item) => {
                        MenuStore.updateItemInEditableMenu(item);
                        return MenuStore.updateShowcaseMenu();
                    }}
                />
            case "integration":
                return <IntegrationForm
                    onSubmit={(item) => AdminStore.updateIntegration(item)}
                />
            case "newIntegration":
                return <IntegrationForm
                    onSubmit={(item) => AdminStore.createIntegration(item)}
                />
            case "menu":
                return <MenuForm
                    onSubmit={(item) => MenuStore.updateShowcaseMenu(item, { replaceTables: true })}
                />
            case "newMenu":
                return <MenuForm
                    mode={"newMenu"}
                    onSubmit={(item) => {
                        // console.log("MenuStore.menu[0].id)", MenuStore.showcase?.[0].id)
                        try {
                            let res = MenuStore.updateShowcaseMenu(item);
                            navigate(`/menu`);
                            return res
                        } catch (error) {
                            console.error("MenuForm updateShowcaseMenu Fail", error.message)
                        }
                    }}
                />
            case "modificator":
                return <ModificatorForm
                    mode={"catalog"}
                    onSubmit={CatalogStore.updateCatalogItem}
                />
            case "newModificator":
                return <ModificatorForm
                    mode={"catalog"}
                    onSubmit={(item) => {
                        console.log("ModificatorForm submit MenuStore", item);
                        return CatalogStore.createModificator(item);
                    }}
                />
            case "showcaseModificator":
                return <ModificatorForm
                    mode={"showcase"}
                    onSubmit={(item) => {
                        MenuStore.updateItemInEditableMenu(item);
                        return MenuStore.updateShowcaseMenu()
                    }}
                />
            case "newGroupModificator":
                return <GroupModificatorForm
                    onSubmit={(item) => CatalogStore.createGroup(item, 2)}
                />


            case "newKassa":
                return <KassaForm
                    onSubmit={(item) => KassaStore.createKassa(item)}
                />
            case "kassa":
                return <KassaForm
                    onSubmit={(item) => KassaStore.createKassa(item)}
                />


            case "orderDetails":
                return <OrderDetails />
            case "auth":
                return <div className="popup_container"> <AuthForm /> </div>
            case "qr":
                return <QRcomponent />
            default:
                break;
        }
    }

    return showForm();

};

export default observer(ShowForm);