import axios from "axios";

import { ConfigurationManager } from "../config";

export class ConnectionManager {
    static _instance;
    api;

    callback;

    constructor() {

        this.api = axios.create({
            withCredentials: false,
            baseURL: ConfigurationManager.GetInstance().getItem("API_BASE"),
        });

        this.api.interceptors.request.use((config) => {
            if (!config.headers) config.headers = {};

            config.headers = {
                ...config.headers,
                // "Accept-Language": "ru-RU,ru;",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            return config;
        });

        this.api.interceptors.response.use(
            (config) => {
                return config;
            },
            async (error) => {
                //перехватчик на ответ, если ошибка сервера
                // на обновление токена.
                const originalRequest = error.config;
                if (error.response.status === 401 && error.config && !error.config._isRetry) {
                    originalRequest._isRetry = true;
                    console.log("Пользователь не авторизован");

                    if (this.callback) this.callback();
                }
                throw error;
            }
        );
    }

    setCallback(callback) {
        this.callback = callback
        console.log("Callback is seteed", this.callback);
    }

    GetClient() {
        return this.api;
    }

    static GetInstance() {
        return this._instance ?? (this._instance = new ConnectionManager());
    }
}

export class CloudPayConnectionManager {
    static _instance;
    api;

    callback;

    constructor() {

        this.api = axios.create({
            withCredentials: false,
            baseURL: ConfigurationManager.GetInstance().getItem("PAY_SERVICE"),
        });

        this.api.interceptors.request.use((config) => {
            if (!config.headers) config.headers = {};

            config.headers = {
                ...config.headers,
                // "Accept-Language": "ru-RU,ru;",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            return config;
        });

        this.api.interceptors.response.use(
            (config) => {
                return config;
            },
            async (error) => {
                //перехватчик на ответ, если ошибка сервера
                // на обновление токена.
                const originalRequest = error.config;
                if (error.response.status === 401 && error.config && !error.config._isRetry) {
                    originalRequest._isRetry = true;
                    console.log("Пользователь не авторизован");

                    if (this.callback) this.callback();
                }
                throw error;
            }
        );
    }

    setCallback(callback) {
        this.callback = callback
        console.log("Callback is seteed", this.callback);
    }

    GetClient() {
        return this.api;
    }

    static GetInstance() {
        return this._instance ?? (this._instance = new CloudPayConnectionManager());
    }
}