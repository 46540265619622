import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import styles from './OrderListPage.module.scss';

import AdminStore from '../../store/AdminStore';
import MenuStore from '../../store/MenuStore';
import OrderStore from '../../store/OrderStore';

import Tabs from 'devextreme-react/tabs';

import Page from '../../Layout/Page.tsx'

import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    SearchPanel,
} from 'devextreme-react/data-grid';

import OrderList from '../../components/OrderList';

const OrderListPage = () => {

    useEffect(() => {
        if (MenuStore.showcase?.[0]?.id === "0") {
            MenuStore.fetchShowcase()
        }
        if (AdminStore.integrationsList?.length === 0) {
            AdminStore.fetchIntegrationsList();
        }
        OrderStore.fetchOrderList();
    }, [])

    const longtabs = [
        { text: 'Table - сервис' },
        { text: 'Доставка' },
        { text: 'Расходные накладные' },
        { text: 'Приходные накладные' },
    ];

    const [tabIndex, setTabIndex] = useState(0)

    const onTabChange = (e) => {
        if (e.itemIndex === tabIndex) return;
        console.log("onTabChange event", e);
        setTabIndex(e.itemIndex);
    }

    const orderStoreFilter = (showcaseType) => OrderStore.orderListWithData.filter(item => item.showcaseType === showcaseType)

    const getDataSourceOption = () => {
        switch (tabIndex) {
            case 0: return orderStoreFilter(1)
            case 1: return orderStoreFilter(2)
            case 2: return orderStoreFilter(3)
            case 3: return orderStoreFilter(4)
            default: break;
        }
    }

    return (
            <Page>
                <div id="longtabs">
                    <Tabs
                        dataSource={longtabs}
                        onItemClick={onTabChange}
                        selectedIndex={tabIndex}
                    />
                </div>
                <div className={styles.list}>
                    {[0].includes(tabIndex) && //table сервис
                        <>
                            <OrderList dataSource={getDataSourceOption()} >
                                <Column dataField="tableNumber" />
                            </OrderList>
                        </>
                    }
                    {[1].includes(tabIndex) && //доставка
                        <OrderList dataSource={getDataSourceOption()} >
                            <Column dataField="address.city" />
                            <Column dataField="email" />
                            <Column dataField="phone" />
                        </OrderList>
                    }
                    {[2, 3].includes(tabIndex) && //расходные && приходные накладные
                        <OrderList dataSource={getDataSourceOption()}                    >
                            <Column dataField="contragentId" caption="Id контрагента" />
                            <Column dataField="contragentInn" caption="ИНН контрагента" />
                            <Column dataField="stockId" caption="Id склада" />
                        </OrderList>
                    }
                </div>
            </Page>
    );
};

export default observer(OrderListPage);