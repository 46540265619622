import { Button } from 'devextreme-react/select-box';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import MenuStore from '../../../../store/MenuStore';

import QueryRules from './QueryRules/QueryRules';

const QueryRulesList = () => {
    console.log("QueryRulesList", toJS(MenuStore.editableMenuAddons))
    
    return (
        <div>
            {MenuStore.editableMenuAddons?.map(addon => <QueryRules key={addon.id} addon={addon} />)}
        </div>
    );
};

export default observer(QueryRulesList);