import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import "./App.scss";

import AuthPage from "./pages/AuthPage";
import MenuPage from "./pages/MenuPage";
import CatalogPage from "./pages/CatalogPage";

import IntegrationsPage from "./pages/IntegrationsPage";
import AuthStore from "./store/AuthStore";
import Status from "./components/Status";
import { ConfigurationManager } from "./config";
import WithAuth from "./hoc/WithAuth";

import 'devextreme/dist/css/dx.light.css';
import OrderListPage from "./pages/OrderListPage";

import KassaPage from './pages/KassaPage'

function App() {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const login = localStorage.getItem("login");
    AuthStore.setUserName(login);
  }, []);

  useEffect(() => {
    ConfigurationManager.GetInstance()
      .fetch()
      .then(() => setIsInitialized(true))
      .catch(() => setError("Ошибка загрузки конфигурации"));
  }, []);

  return !isInitialized || error ? (
    <Status message={error ?? "Загрузка конфигурации"} />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WithAuth><MenuPage /></WithAuth>} />
        <Route path="menu" element={<WithAuth><MenuPage /></WithAuth>}>
          <Route path=":menuId" element={<WithAuth><MenuPage /></WithAuth>} />
        </Route>
        <Route path="catalog" element={<WithAuth><CatalogPage /></WithAuth>} />
        <Route path="integrations" element={<WithAuth><IntegrationsPage /></WithAuth>} />
        <Route path="orderList" element={<WithAuth><OrderListPage /></WithAuth>} />
        <Route path="kassa" element={<WithAuth><KassaPage /></WithAuth>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
