import { kassa } from "types";
import KassaItem from "../KassaItem";

import styles from '../KassaPage.module.scss';
import { observer } from "mobx-react-lite";
import KassaStore from "store/KassaStore";
import { useEffect } from "react";
import { toJS } from "mobx";

const KassaList = ({ items }: { items: kassa[] }) => {
  useEffect(() => {
    // setIsLoading(true);
    KassaStore.fetchKassaList()//.finally(() => setIsLoading(false));
  }, [])

  console.log("KassaList", toJS(items))
  return (
    <div className={styles.item_list}>
      <table className={styles.item_list_table}>
        <thead>
          <tr className={styles.item}>
            <td>
              <div>Имя</div>
            </td>
            <td>
              <div>id</div>
            </td>
            <td>
              <div>ИНН</div>
            </td>
            <td>
              <div>Система налогообложения</div>
            </td>
            <td>
              <div>Фискализация</div>
            </td>
          </tr>
        </thead>
        <tbody>
          {KassaStore.kassaList?.length > 0 ? KassaStore.kassaList.map(item => <KassaItem key={item.id} item={item} />) : null}
        </tbody>
      </table>
    </div>
  )
}

export default observer(KassaList);
