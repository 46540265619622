import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import AuthStore from '../store/AuthStore';

import { observer } from 'mobx-react-lite';

import AuthPage from '../pages/AuthPage'
import {ConnectionManager, CloudPayConnectionManager} from '../http/axios';
import PopupStore from '../store/PopupStore';

const WithAuth = ({ children }) => {
    console.log("WithAuth AuthStore.isAuth", AuthStore.isAuth)

    useEffect( () => {
        ConnectionManager.GetInstance().setCallback( () => PopupStore.setActiveForm("auth"));
        CloudPayConnectionManager.GetInstance().setCallback( () => PopupStore.setActiveForm("auth"));
    }, [])

    return (
        <>
            {AuthStore.isAuth ? children : <AuthPage />}
        </>
    );
};

export default observer(WithAuth);