import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';

import PopupStore from '../../../store/PopupStore';
import AdminStore from '../../../store/AdminStore';

import Form, { ButtonItem, Label, SimpleItem } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import { RequiredRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { changeTo } from '../../../scripts/changeTo';
import FormWrapper from '../FormWrapper';
import {defaultIntegration} from "../../../types/const";

const IntegrationForm = ({
    onSubmit = () => console.log("IntegrationForm onSubmit")
}) => {

    const [orgs, setOrgs] = useState([]);
    const [terminalGroups, setTerminalGroups] = useState([]);
    const [paymentSystem, setPaymentSystem] = useState([]);

    const closeForm = () => PopupStore.setActiveForm('')


    const getOrganizationHandler = (e, values) => {
        AdminStore.fetchOrganisation(values)
            .then(data => setOrgs(data));
    }

    console.log("paymentSystem", paymentSystem)

    //devExtreme form
    const InitialIntegrationFormData = {
        ...defaultIntegration,
        ...AdminStore.activeIntegration,
    }

    const integrationTypeOptions = [
        { label: "Локальный каталог", value: 0 },
        { label: "IIKO Biz", value: 1 },
        { label: "IIKO Transport", value: 2 },
        { label: "RMS", value: 3 },
    ]

    const [integrationFormData, setIntegrationFormData] = useState(InitialIntegrationFormData)

    const onFieldDataChangedIntegrationForm = (e) => {
        setIntegrationFormData(e.component.option("formData"))
    }

    const onValueChangedCatalogType = useCallback((e) => {
        if (e.previousValue === e.value) return
        setTerminalGroups([]);
        setPaymentSystem([]);
        setOrgs([]);
        setIntegrationFormData((state) => ({ ...state, type: e.value, paymentSystem: {}, organization: '' }));
    }, []);

    const getRestoFromIntegrationButtonOptions = {
        text: " Получить рестораны из интеграции",
        type: "success",
        onClick: (e) => getOrganizationHandler(e, integrationFormData),
    };

    const organisationsOptions = orgs?.length > 0 ? orgs.map((item) => ({ label: item.name, value: item.id })) : []

    const onValueChangedOrganisation = useCallback((e) => {
        if (e.previousValue === e.value) return
        setIntegrationFormData((state) => ({ ...state, organization: e.value }))
        console.log("onClick organization")

        AdminStore.fetchPaymentSystem({ ...integrationFormData, organization: e.value }).then((data) => setPaymentSystem(data));
        AdminStore.fetchTerminalGroups({ ...integrationFormData, organization: e.value }).then((data) => setTerminalGroups(data));
    }, [integrationFormData]);


    //terminalGrops 
    const terminalGroupsOptions = terminalGroups?.length > 0 ? terminalGroups.map((item) => ({ label: item.name, value: item.id })) : []

    const onValueChangedTerminalGroups = useCallback((e) => {
        if (e.previousValue === e.value) return
        setIntegrationFormData((state) => ({ ...state, terminalGroup: e.value }))
        console.log("onClick terminalGrops")
    }, []);

    //payment system
    const paymentSystemOptions = paymentSystem?.length > 0 ? paymentSystem.map((item) => ({ label: item.name, value: item.id })) : []

    const onValueChangedPaymentSystem = useCallback((e) => {
        if (e.previousValue === e.value) return
        // console.log("onValueChangedPaymentSystem", e);
        let paymentSystemObj = paymentSystem?.find(item => item.id === e.value);
        setIntegrationFormData((state) => ({ ...state, paymentSystem: paymentSystemObj }));
        console.log("onClick terminalGrops");
    }, [paymentSystem]);

    // submit Btn Options

    // const submitBtnOptions = {
    //     text: "Создать ресторан",
    //     type: "success",
    //     useSubmitBehavior: true,
    //     // onClick: (e) => onSubmit(integrationFormData).then(res => res.status === 202 && closeForm())
    // };

    const handleSubmit = (e) => {
        const data = changeTo(integrationFormData, null);
        console.log("handleSubmit", data)
        onSubmit(data).then(res => res.status === 202 && closeForm())
    };


    // /devExtreme form

    console.log("integrationFormData type", integrationFormData.type)

    return (
        <FormWrapper title={"Добавить ресторан"}>

            <Form formData={integrationFormData} labelLocation="top" onFieldDataChanged={onFieldDataChangedIntegrationForm}>
                <SimpleItem dataField="name" editorType="dxTextBox">
                    <Label text="Название ресторана" />
                    <RequiredRule message="Обязательно к заполнению" />
                </SimpleItem>
                <SimpleItem render={() => (
                    <SelectBox
                        dataSource={integrationTypeOptions}
                        editorType="dxSelectBox"
                        editorOptions={{ items: integrationTypeOptions }}
                        displayExpr="label"
                        valueExpr="value"
                        onValueChanged={onValueChangedCatalogType}
                        defaultValue={integrationFormData.type}
                        disabled={PopupStore.activeForm === "integration" ? true : false}
                    />
                )}
                >
                    <Label text="Тип каталога" />
                </SimpleItem>

                {[1, 2, 3].includes(integrationFormData.type) &&
                    <SimpleItem dataField="login" editorType="dxTextBox">
                        <Label text="Логин интеграции" />
                        <RequiredRule message="Обязательно к заполнению" />
                    </SimpleItem>
                }

                {[1, 3].includes(integrationFormData.type) &&
                    <SimpleItem dataField="password" editorType="dxTextBox">
                        <Label text="Пароль интеграции" />
                        <RequiredRule message="Обязательно к заполнению" />
                    </SimpleItem>
                }

                {[3].includes(integrationFormData.type) &&
                    <SimpleItem dataField="hostPort" editorType="dxTextBox">
                        <Label text="Адрес хоста" />
                        <RequiredRule message="Обязательно к заполнению" />
                    </SimpleItem>
                }

                {[1, 2].includes(integrationFormData.type) &&
                    <ButtonItem
                        buttonOptions={getRestoFromIntegrationButtonOptions}
                        horizontalAlignment={"right"}
                    />
                }

                {[1, 2].includes(integrationFormData.type) && orgs?.length > 0 &&
                    <SimpleItem render={() => (
                        <SelectBox
                            dataSource={organisationsOptions}
                            editorType="dxSelectBox"
                            editorOptions={{ items: organisationsOptions }}
                            displayExpr="label"
                            valueExpr="value"
                            onValueChanged={onValueChangedOrganisation}
                            defaultValue={integrationFormData.organization}
                        />
                    )}
                    >
                        <Label text="Рестораны" />
                    </SimpleItem>
                }

                {[2].includes(integrationFormData.type) && terminalGroups?.length > 0 &&
                    <SimpleItem render={() => (
                        <SelectBox
                            dataSource={terminalGroupsOptions}
                            editorType="dxSelectBox"
                            editorOptions={{ items: terminalGroupsOptions }}
                            displayExpr="label"
                            valueExpr="value"
                            onValueChanged={onValueChangedTerminalGroups}
                            defaultValue={integrationFormData.terminalGroup}
                        />
                    )}
                    >
                        <Label text="Группа терминалов" />
                    </SimpleItem>
                }

                {[1, 2].includes(integrationFormData.type) && paymentSystem?.length > 0 &&
                    <SimpleItem render={() => (
                        <SelectBox
                            dataSource={paymentSystemOptions}
                            editorType="dxSelectBox"
                            editorOptions={{ items: paymentSystemOptions }}
                            displayExpr="label"
                            valueExpr="value"
                            onValueChanged={onValueChangedPaymentSystem}
                            defaultValue={integrationFormData.paymentSystemId}
                        />
                    )}
                    >
                        <Label text="Платежная система" />
                    </SimpleItem>
                }
            </Form>
            <Button
                text="Сохранить"
                stylingMode={"contained"}
                type="success"
                icon="floppy"
                onClick={handleSubmit}
            />
        </FormWrapper>
    )
};

export default observer(IntegrationForm);

