import classNames from 'classnames';
import React from 'react';
import PopupStore from '../../store/PopupStore';

import styles from './forms.module.scss';

const FormWrapper = ({ children, title = '' }: { children?: React.ReactNode; title: string }) => {
    const closeForm = () => PopupStore.setActiveForm('');

    return (
        <div className={classNames(styles.popup_container, styles.active)}>
            <div className={styles.popup}>
                <div className={styles.close_btn}
                    onClick={() => closeForm()}
                >
                    <i className="fas fa-times"></i>
                </div>

                <div className={styles.form_wrapper}>
                    <div className={styles.popup_title}>
                        {title}
                    </div>
                    {children}
                </div>
            </div>
        </div>

    );
};

export default FormWrapper;