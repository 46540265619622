import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
// import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";

import styles from '../forms.module.scss';
import classnames from 'classnames';

import CatalogStore from '../../../store/CatalogStore';
import PopupStore from '../../../store/PopupStore';
import MenuStore from '../../../store/MenuStore';
import AdminStore from '../../../store/AdminStore';
import FormWrapper from '../FormWrapper';

import Form, { Label, SimpleItem } from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import { RequiredRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';


const GroupModificatorForm = ({
    onSubmit = () => console.log("GroupForm onSubmit"),
    mode = "catalog"
}) => {

    const closeForm = () => {
        PopupStore.setActiveForm('');
    }

    //dev Extreme Form
    const initialGroupFormData = { ...CatalogStore.catalogActiveItem }

    const [groupFormData, setGroupFormData] = useState(initialGroupFormData)

    const onFieldDataChangedGroupForm = (e) => {
        setGroupFormData(e.component.option("formData"));
    }

    const onSubmitHandler = () => {
        onSubmit(groupFormData).then(res => res.status === 202 && closeForm());
        // closeForm()
    }

    const showParentGroupOptions = (items) => {
        let res = AdminStore.parentIdRecursionChecker(CatalogStore.catalogActiveItem.id, items)
        return res
            .filter(item => item.type === 2)
            // .map(item => <option key={item.id} value={item.id}>{item.name}</option>)
            .map(item => ({ label: item.name, value: item.id }))
    }

    let parentGroupOptions = [{ label: "Корневая группа", value: '' }]
    switch (mode) {
        case "catalog": parentGroupOptions = [...parentGroupOptions, ...showParentGroupOptions(CatalogStore.catalog)]; break;
        case "showcase": parentGroupOptions = [...parentGroupOptions, ...showParentGroupOptions(MenuStore.editableMenu?.[0]?.items),]; break;
        default: break;
    }

    const onValueChangedParentGroup = useCallback((e) => {
        if (e.previousValue === e.value) return
        setGroupFormData((state) => ({ ...state, parent: e.value }))
        console.log("onClick onValueChangedParentGroup")
    }, []);


    // /dev Extreme Form

    console.log("groupFormData", groupFormData)

    return (
        <FormWrapper title={"Групповой модификатор"}>

            <form /* onSubmit={handleSubmit} */>
                <Form formData={groupFormData} labelLocation="top" onFieldDataChanged={onFieldDataChangedGroupForm} >
                    <SimpleItem dataField="name" editorType="dxTextBox">
                        <Label text="Название группы" />
                        <RequiredRule message="Обязательно к заполнению" />
                    </SimpleItem>
                    <SimpleItem dataField="description" editorType="dxTextBox">
                        <Label text="Описание группы" />
                    </SimpleItem>
                    <SimpleItem dataField="images[0]" editorType="dxTextBox">
                        <Label text="Ссылка на изображение для группы" />
                    </SimpleItem>
                    <SimpleItem dataField="index" editorType="dxTextBox">
                        <Label text="Индекс для сортировки" />
                    </SimpleItem>
                    <SimpleItem render={() => (
                        <SelectBox
                            dataSource={parentGroupOptions}
                            editorType="dxSelectBox"
                            editorOptions={{ items: parentGroupOptions }}
                            displayExpr="label"
                            valueExpr="value"
                            onValueChanged={onValueChangedParentGroup}
                            defaultValue={groupFormData.parent}
                        />
                    )}>
                        <Label text="Родительская группа" />
                    </SimpleItem>
                </Form>
            </form>

            <Button
                text="Сохранить"
                stylingMode={"contained"}
                type="success"
                icon="floppy"
                onClick={onSubmitHandler}
            />

        </FormWrapper>
    )
};

export default observer(GroupModificatorForm);

