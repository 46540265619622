import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import PopupStore from '../../store/PopupStore';
import CatalogStore from '../../store/CatalogStore';
import MenuStore from '../../store/MenuStore';
import AdminStore from '../../store/AdminStore';
import KassaStore from '../../store/KassaStore';

import Spinner from '../../components/exeptions/Spinner';

import Catalog from '../../components/Catalog';

import Page, { ButtonInfo } from '../../Layout/Page';

const CatalogPage = () => {

    const [catalogIsLoading, setCatalogIsLoading] = useState(false);

    useEffect(() => {
        MenuStore.setEditableMenu([]);
        setCatalogIsLoading(true);
        CatalogStore.fetchCatalog().then(() => setCatalogIsLoading(false));
        MenuStore.fetchShowcase();
        KassaStore.fetchKassaList();
    }, [])

    const onCreateNewGroupHandler = (item) => {
        console.debug("onCreateNewGroupHandler item", item);

        PopupStore.setActiveForm("newGroup");
        CatalogStore.setCatalogDefaultActiveGroup();
    }

    const onCreateNewProductHandler = (item) => {
        console.debug("onCreateNewProductHandler item", item);

        PopupStore.setActiveForm("newProduct");
        CatalogStore.setCatalogDefaultActiveProduct();
    }
    const onCreateNewModificatorHandler = (item) => {
        console.debug("onCreateNewModificatorHandler item", item);

        PopupStore.setActiveForm("newModificator");
        CatalogStore.setCatalogDefaultActiveModificator();
    }

    const onCreateNewGroupModificatorHandler = (item) => {
        console.debug("onCreateNewGroupModificatorHandler item", item);

        PopupStore.setActiveForm("newGroupModificator");
        CatalogStore.setCatalogDefaultActiveGroup(4);
    }

    const switchTableViewHandler = () => AdminStore.setTableView(!AdminStore.tableView)

    const buttons: ButtonInfo[] = [
        {
            text: "Создать",
            icon: "fas fa-plus",
            buttons: [
                { text: "Группа", icon: "fas fa-plus", onClick: onCreateNewGroupHandler },
                { text: "Товар", icon: "fas fa-plus", onClick: onCreateNewProductHandler },
                { text: "Модификатор", icon: "fas fa-plus", onClick: onCreateNewModificatorHandler },
                { text: "Групповой модификатор", icon: "fas fa-plus", onClick: onCreateNewGroupModificatorHandler },
            ]
        },
        { text: "Табличный вид", icon: "fas fa-table", onClick: switchTableViewHandler },
    ]

    return (
        <Page buttons={buttons}>
            {catalogIsLoading ? <Spinner /> : <Catalog />}
        </Page>
    );
};

export default observer(CatalogPage);


