import React from "react";
import { useParams } from "react-router-dom";

import styles from "./QRcomponent.module.scss";

import PopupStore from "../../store/PopupStore";

import QRCode from "react-qr-code";
import MenuStore from "../../store/MenuStore";
import { ConfigurationManager } from "../../config";
import { toJS } from "mobx";
import { Item } from "devextreme-react/accordion";
import QRItem from "./QRItem";

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import QRList from "./QRList";


const QRcomponent = () => {
  const { menuId } = useParams();
  // const domain = ConfigurationManager.GetInstance().getItem("QR_DOMAIN_BASE");
  const domain = ConfigurationManager.GetInstance().getItem("QR_MENU_BASE");

  const tables = MenuStore.editableMenu?.[0]?.tables
  const menuType = MenuStore.editableMenu?.[0]?.type

  console.log("QR tables", toJS(tables), menuType)

  const downloadAllSVGHandler = () => {

    // const elemString = svgQrRef.current.innerHTML
    // console.log("downloadSVGHandler elem", JSZip)

    const arrQr = document.querySelectorAll("[data=qr]")

    const arrQrString = Array.from(arrQr).map(item => item.innerHTML)

    const zip = new JSZip();

    let namePrefix
    if ([1].includes(menuType)) namePrefix = 'Cтол №'
    if ([0, 2].includes(menuType)) namePrefix = ''
    if ([3, 4].includes(menuType)) namePrefix = 'Склад №'


    arrQrString.forEach((item, i) => {
      const table = tables?.[i]

      const filename = table ?
        `${namePrefix} ${table.number}, ${table.name ? table.name : ''} ${table.sectionName ? (`Секция: ${table.sectionName}`) : ''}`
        :
        `foodof-menu`

      zip.file(`${filename}.svg`, item)
    })

    // zip.file("Hello.svg", elemString);

    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        saveAs(content, "foodof_QR.zip");
      });


  }

  return (
    <div className={styles.qr_component}>

      <div className={styles.btn_wrapper}>
        <button
          className={styles.btn_download_qr}
          onClick={() => {
            downloadAllSVGHandler();
          }}
        >
          Скачать все
        </button>
      </div>

      <div className={styles.qr_wrapper}>
        <div
          className={styles.close_btn}
          onClick={() => {
            PopupStore.setActiveForm("");
          }}
        >
          <i className="fas fa-times"></i>
        </div>


        {/* {tables?.length > 0 && menuType === 1 && (
          tables?.map((item) => (
            // столы
            <QRItem key={item.id} item={item} namePrefix={"Стол №"} />
          ))
        )} */}

        {[1].includes(menuType) && (
          <QRList tables={tables} namePrefix={"Стол №"} noFoundMessage={"Выберите столы"} />
        )}

        {[3, 4].includes(menuType) && (
          <QRList tables={tables} namePrefix={"Склад №"} noFoundMessage={"Выберите склады"} />
        )}

        {[0, 2].includes(menuType) && (
          <>
            <div className={styles.qr}>
              <a className={styles.qr_title} href={`${domain}/showcase/${menuId}/?tableId=none`} target="_blank" rel="noreferrer">
                <div data={"qr"}>
                  <QRCode value={`${domain}/showcase/${menuId}/?tableId=none`} />
                </div>
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QRcomponent;
