import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AuthForm.module.scss';

import AuthStore from '../../store/AuthStore';
import PopupStore from '../../store/PopupStore';

import * as Yup from "yup";


import Form, { ButtonItem, Label, SimpleItem, GroupItem } from 'devextreme-react/form';
import { CompareRule, CustomRule, RequiredRule } from 'devextreme-react/validator';

const AuthForm = () => {
    const [isForgetPassword, setIsForgetPassword] = useState(false);
    const [loading, setIsLoading] = useState(false);

    // меняет форму с login на register
    const [loginRegisterSwitcher, setLoginRegisterSwitcher] = useState(false);

    let loginShowButtonActive = styles.login_show_btn;
    let registerShowButtonActive = styles.login_show_btn;

    let form_slider = styles.form_slider;

    if (loginRegisterSwitcher) {
        registerShowButtonActive = `${styles.login_show_btn} ${styles.active}`;
        form_slider = `${styles.form_slider} ${styles.move_left}`;
    } else {
        loginShowButtonActive = `${styles.login_show_btn} ${styles.active}`;
    }

    //  /меняет форму с login на register
    const navigate = useNavigate();

    // Dev Extreme restorePassword form data
    const initialAuthForgetPassData = {
        login: "",
        newPassword: "",
    }

    const [authForgetPassData, setAuthForgetPassData] = useState({ initialAuthForgetPassData })

    const onFieldDataChangeRestorePassword = (e) => {
        console.log("onFieldDataChangeRestorePassword event", e)
        setAuthForgetPassData(e.component.option("formData"))
    }

    const restorePasswordButtonOptions = {
        text: "Восстановить пароль",
        type: "success",
        useSubmitBehavior: true
    };

    const restorePasswordCancel = {
        text: "Отмена",
        onClick: () => { setIsForgetPassword(false) }
        // useSubmitBehavior: true
    };

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        if (loading) return;

        setIsLoading(true);
        const data = new FormData(e.target);
        const { login, newPassword } = Object.fromEntries(data.entries());
        console.log("handleSubmit", { login, newPassword });

        AuthStore.forgetPassword({ login, newPassword })
            .finally(() => setIsLoading(false));

    }, [loading]);

    const passwordOptions = {
        mode: 'password',
    };

    const passwordComparison = () => {
        return authForgetPassData.newPassword
    }

    // Dev Extreme login form data 
    const initialLoginFormData = {
        login: "",
        password: "",
    }

    const [loginFormData, setLoginFormData] = useState({ initialLoginFormData })

    const onFieldDataChangeLoginForm = (e) => {
        console.log("onFieldDataChangeLoginForm event", e)
        setLoginFormData(e.component.option("formData"))
    }

    const loginButtonOptions = {
        text: "Войти",
        type: "success",
        // onClick: (e) => {onLoginSubmit(e)}
        useSubmitBehavior: true
    };

    const onLoginSubmit = useCallback((e) => {
        e.preventDefault();
        if (loading) return;
        setIsLoading(true);

        const data = new FormData(e.target)
        const { login, password } = Object.fromEntries(data.entries());
        console.log("onLoginSubmit", { login, password })

        AuthStore.login({ login, password })
            .then(() => {
                navigate('/integrations');
                PopupStore.setActiveForm('');
            })
            .finally(() => setIsLoading(false));

    }, [loading, navigate]);

    // Dev Extreme registration form data 
    const initialRegistrationFormData = {
        login: "",
        password: "",
    }

    const [registrationFormData, setRegistrationFormData] = useState({ initialRegistrationFormData })

    const onFieldDataChangeRegistrationForm = (e) => {
        console.log("onFieldDataChangeRegistrationForm event", e)
        setRegistrationFormData(e.component.option("formData"))
    }

    const registrationButtonOptions = {
        text: "Зарегистрироваться",
        type: "success",
        useSubmitBehavior: true
    };

    const onRegistrationSubmit = useCallback((e) => {
        e.preventDefault();
        if (loading) return;
        setIsLoading(true)

        const data = new FormData(e.target)
        const { login, password } = Object.fromEntries(data.entries());
        console.log("onRegistrationSubmit", { login, password })

        AuthStore.registration({ login, password })
            .finally(() => setIsLoading(false))

    }, [loading]);

    const emailValidationRule = (arg) => {
        const email = arg.value
        const schema = Yup.string().trim().email('Введите корректный email')
        return schema.isValidSync(email)
    }

    return (
        <div className={styles.auth_form}>
            <div className={styles.toggle_form_btn}>
                <div className={loginShowButtonActive}
                    onClick={() => setLoginRegisterSwitcher(false)}
                > Логин</div>
                <div className={registerShowButtonActive}
                    onClick={() => setLoginRegisterSwitcher(true)}
                > Регистрация</div>
            </div>

            <div className={styles.form_slider_hider}>
                <div className={form_slider}>

                    <div className={styles.login_form}>

                        {isForgetPassword ?
                            <form onSubmit={handleSubmit}>
                                <div className={styles.title}>
                                    Смена пароля
                                </div>
                                <Form formData={authForgetPassData} labelLocation="top" onFieldDataChanged={onFieldDataChangeRestorePassword}>
                                    <SimpleItem dataField="login" editorType="dxTextBox">
                                        <Label text="Ваш логин" />
                                        <RequiredRule message="Обязательное поле" />
                                        {/* <EmailRule message="Email is invalid" /> */}
                                        <CustomRule
                                            validationCallback={(arg) => emailValidationRule(arg)}
                                            message="Введите корректный логин"
                                        />
                                    </SimpleItem>
                                    <SimpleItem dataField="newPassword" editorType="dxTextBox" editorOptions={passwordOptions}>
                                        <Label text="Новый пароль" />
                                        <RequiredRule message="Обязательное поле" />
                                    </SimpleItem>
                                    <SimpleItem editorType="dxTextBox" editorOptions={passwordOptions}>
                                        <Label text="Подтвердите пароль" />
                                        <RequiredRule message="Обязательное поле" />
                                        <CompareRule
                                            message="Пароли должны совпадать"
                                            comparisonTarget={passwordComparison}
                                        />
                                    </SimpleItem>
                                    <GroupItem colCount={2}>
                                        <ButtonItem
                                            // visible={!loading}
                                            buttonOptions={{ ...restorePasswordButtonOptions, stylingMode: loading ? "outlined" : "contained" }}
                                            horizontalAlignment={"left"}
                                        />
                                        <ButtonItem
                                            buttonOptions={restorePasswordCancel}
                                            horizontalAlignment={"right"}
                                        />
                                    </GroupItem>
                                </Form>
                            </form>
                            :
                            <form onSubmit={onLoginSubmit}>
                                <div className={styles.title}>
                                    Вход
                                </div>
                                <Form formData={loginFormData} labelLocation="top" onFieldDataChanged={onFieldDataChangeLoginForm}>
                                    <SimpleItem dataField="login" editorType="dxTextBox">
                                        <Label text="Логин" />
                                        <RequiredRule message="Email is required" />
                                        {/* <EmailRule message="Email is invalid" /> */}
                                        <CustomRule
                                            validationCallback={(arg) => emailValidationRule(arg)}
                                            message="Введите корректный логин"
                                        />
                                    </SimpleItem>
                                    <SimpleItem
                                        dataField="password"
                                        editorType="dxTextBox"
                                        editorOptions={passwordOptions}
                                    >
                                        <Label text="Пароль" />
                                        <RequiredRule message="Password is required" />
                                    </SimpleItem>
                                    <ButtonItem
                                        // visible={!loading}
                                        buttonOptions={{ ...loginButtonOptions, stylingMode: loading ? "outlined" : "contained" }}
                                        horizontalAlignment={"left"}
                                    />
                                </Form>
                                <div className={styles.forget_password}>
                                    <span onClick={() => { setIsForgetPassword(true) }}>Забыли пароль?</span>
                                </div>
                            </form>
                        }
                    </div>

                    <div className={styles.login_form}>
                        <form onSubmit={onRegistrationSubmit}>
                            <div className={styles.title}>
                                Регистрация
                            </div>
                            <Form formData={registrationFormData} labelLocation="top" onFieldDataChanged={onFieldDataChangeRegistrationForm}>
                                <SimpleItem dataField="login" editorType="dxTextBox">
                                    <Label text="Логин" />
                                    <RequiredRule message="Обязательное поле" />
                                    <CustomRule
                                        validationCallback={(arg) => emailValidationRule(arg)}
                                        message="Введите корректный логин"
                                    />
                                </SimpleItem>
                                <SimpleItem dataField="password" editorType="dxTextBox" editorOptions={passwordOptions}>
                                    <Label text="Пароль" />
                                    <RequiredRule message="Обязательное поле" />
                                </SimpleItem>
                                <div className={styles.forget_password}>
                                    <span onClick={() => { setIsForgetPassword(true) }}>Забыли пароль?</span>
                                </div>
                                <ButtonItem
                                    buttonOptions={{ ...registrationButtonOptions, stylingMode: loading ? "outlined" : "contained" }}
                                    horizontalAlignment={"left"}
                                />
                            </Form>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AuthForm;