import React, { useState } from 'react';

import DataGrid, {
    Editing,
    Column,
    Lookup,
    RequiredRule,
} from 'devextreme-react/data-grid';

import { CustomRule } from 'devextreme-react/validator';

// import { CustomRule } from "devextreme/common" 

import SelectBox from 'devextreme-react/select-box';
import styles from '../forms.module.scss';

const dayOfWeek = [
    { value: "Monday", name: "Понедельник" },
    { value: "Tuesday", name: "Вторник" },
    { value: "Wednesday", name: "Среда" },
    { value: "Thursday", name: "Четверг" },
    { value: "Friday", name: "Пятница" },
    { value: "Saturday", name: "Суббота" },
    { value: "Sunday", name: "Воскресенье" },
]

const DxHoursOfWork = ({ hoursOfWork, onChangeHandler }) => {

    const onValueChanged = (cell, e) => {
        cell.setValue(e.value);
    }

    const statusEditorRender = (cell) => {
        const onValueChangedEditor = onValueChanged.bind(this, cell);
        console.log(cell.column.lookup)
        return <SelectBox
            defaultValue={cell.value}
            {...cell.column.lookup}
            onValueChanged={onValueChangedEditor}
            itemRender={itemRender}
        />;
    }

    const itemRender = (data) => {
        if (data != null) {
            return <div>
                {/* <img src={imageSource} className="status-icon middle"></img> */}
                <span className="middle">{data.name}</span>
            </div>;
        }
        return <span>(All)</span>;
    }

    return (
        <div>
            <DataGrid
                dataSource={hoursOfWork}
                showBorders={true}
            >
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}
                    useIcons={true}
                />
                <Column
                    dataField="day"
                    caption="День"
                    // width={200}
                    editCellRender={statusEditorRender}
                >
                    <Lookup
                        dataSource={dayOfWeek}
                        displayExpr="name"
                        valueExpr="value"
                    />
                    <RequiredRule
                        message="Обязательное поле"
                    />
                </Column>

                <Column
                    dataField="opening"
                    caption="С"
                    dataType="datetime"
                    format={"HH:mm:ss"}
                    editorOptions={{ type: 'time' }}
                >
                    <RequiredRule
                        message="Обязательное поле"
                    />
                    <CustomRule
                        validationCallback={(arg) => {
                            if (!arg.data.closure) return true
                            if (arg.data.closure > arg.data.opening) return true
                            return false
                        }}
                        message="Время окончания должно быть больше чем время начала работы"
                    />
                </Column>

                <Column
                    dataField="closure"
                    caption="По"
                    dataType="datetime"
                    format={"HH:mm:ss"}
                    editorOptions={{ type: 'time' }}
                >
                    <RequiredRule
                        message="Обязательное поле"
                    />
                    <CustomRule
                        validationCallback={(arg) => {
                            if (!arg.data.opening) return true
                            if (arg.data.closure > arg.data.opening) return true
                            return false
                        }}
                        message="Время окончания должно быть больше чем время начала работы"
                    />
                </Column>

                <Column
                    dataField="isWeekend"
                    caption="Выходной"
                    dataType="boolean"
                >
                </Column>
            </DataGrid>
            <div className={styles.tables}>

            </div>
        </div>
    );
};

export default DxHoursOfWork;