export class ConfigurationManager {
  static _instance;
  _config;

  async fetch(filename = "/config.json") {
    const r = await fetch(filename);
    const json = await r.json();

    console.log("json ConfigurationManager", json)

    this._config = json;
  }

  getItem(key, defaultValue = "") {
    return this._config[key] ?? defaultValue;
  }

  static GetInstance() {
    if (!this._instance) {
      this._instance = new ConfigurationManager();
    }

    return this._instance;
  }
}