import { makeAutoObservable } from "mobx";

import {ConnectionManager} from '../http/axios';

import PopupStore from "./PopupStore";
import AdminStore from "./AdminStore";
import MenuStore from "./MenuStore";
import MsgStore from "./MsgStore";

class CatalogStore {

    catalog = [];

    catalogActiveItem = {
        // id: null,
        name: '',
        parent: '',
        // images: []
    };

    catalogFetchTime = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setCatalog(catalog) {
        this.catalog = catalog;
    }

    setCatalogActiveItem(catalogActiveItem) {
        console.log('setCatalogActiveItem')
        for (let key in catalogActiveItem) {
            if (catalogActiveItem[key] === null) catalogActiveItem[key] = '';
        }

        this.catalogActiveItem = catalogActiveItem;
    }

    setCatalogFetchTime(catalogFetchTime) {
        this.catalogFetchTime = catalogFetchTime;
    }

    setCatalogDefaultActiveProduct() {
        const defaultActiveItem = {
            // "id": "2fa50767-6c4f-4f24-9a21-8321cbaa0ed1",
            // "item": "502ff9c1-a12d-4332-8570-2510b2e03aad",
            "parent": '',
            "name": "",
            "description": "",
            "type": 1,
            "ingredients": '',
            "images": [
                "https://storage.yandexcloud.net/ecb/1605523371690_270x220.jpeg"
            ],
            "measure": 'шт.',
            "currency": 'RUB',
            "price": 100,
            sizes: [],
            modifiers: [],
            groupModifiers: [],
            // "preparing": "00:00:00"
        }
        this.setCatalogActiveItem(defaultActiveItem);
    }

    setCatalogDefaultActiveGroup(type = 2) {
        const defaultActiveItem = {
            // "id": "2fa50767-6c4f-4f24-9a21-8321cbaa0ed1",
            // "item": "502ff9c1-a12d-4332-8570-2510b2e03aad",
            parent: '',
            name: "",
            description: "",
            type,
            images: [

            ],
            modifiers: [],
            groupModifiers: [],

        }
        this.setCatalogActiveItem(defaultActiveItem);
    }

    setCatalogDefaultActiveModificator() {
        const defaultActiveItem = {
            // "id": "2fa50767-6c4f-4f24-9a21-8321cbaa0ed1",
            // "item": "502ff9c1-a12d-4332-8570-2510b2e03aad",
            "parent": '',
            "name": "",
            "description": "",
            "type": 3,
            "ingredients": '',
            "images": [
                "https://cdn.dodostatic.net/static/Img/Ingredients/000D3A39D824A82E11E9AFA7AC1A1D67"
            ],
            "measure": 'шт.',
            "currency": 'RUB',
            "price": 100,
            modifiers: [],
            groupModifiers: [],
            // "preparing": "00:00:00"
        }
        this.setCatalogActiveItem(defaultActiveItem);
    }

    setUpdateItemInCatalog(item) {
        this.catalog = this.catalog.map(catalogItem => catalogItem.id === item.id ? catalogItem = item : catalogItem)
    }

    getCatalogGroups() {
        return this.catalog.filter(item => item.type === 2)
    }

    async fetchCatalog(restoId = false) {
        // костыль, чтобы асинхронные запросы не перетирали друг друга, т. к. каталог зранится в одной переменной
        const now = new Date().getTime();
        if (this.catalogFetchTime < now) this.setCatalogFetchTime(now)


        AdminStore.setCatalogIsLoading(true);

        try {
            let res
            if (!restoId) {
                // await ConnectionManager.setCallback(() => PopupStore.setActiveForm("auth"))
                res = await ConnectionManager.GetInstance(() => PopupStore.setActiveForm("auth")).GetClient().get("/catalog");
            } else {
                res = await ConnectionManager.GetInstance(() => PopupStore.setActiveForm("auth")).GetClient().get(`/integrations/${restoId}/catalog`);
            }

            console.log(window.history)

            // // !!! mock catalog 
            // res.data = catalog;
            // // /mock catalog 

            // костыль, чтобы асинхронные запросы не перетирали друг друга, т. к. каталог зранится в одной переменной
            if (this.catalogFetchTime > now) return

            const result = res.data.sort((item) => {
                if (item.type === 1) return -1;
                return 1
            })

            // //костыль обнуляет модифаерс
            // for (let item of result) {
            //     console.debug(item)
            //     item.modifiers = []
            // }


            console.log("fetchCatalog catalog", result)

            this.setCatalog(result);
            AdminStore.setCatalogIsLoading(false);
            console.log('fetchCatalog sorted', result);
            return result;
        } catch (error) {
            AdminStore.setCatalogIsLoading(false);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка получения каталога", status: "error" });
            this.setCatalog([]);
            console.error('ошибка функции fetchCatalog', error.message)
        }
    }


    createGroup = async (item, type = 2) => {
        // for (let key in item) if (item[key] === '') item[key] = null;

        if (item.parent === '') item.parent = null;

        try {
            console.debug("createGroup item", { ...item, modifiers: [] })
            const res = await ConnectionManager.GetInstance().GetClient().post("/catalog", {
                ...item,
                modifiers: [],
            });
            this.fetchCatalog(MenuStore.editableMenu?.[0]?.integrationId);
            MsgStore.showMsg({ value: "Группа в каталоге успешно создана", status: "ok" });

            return res;
        } catch (error) {
            console.error("ошибка createGroup", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка создания группы в каталоге", status: "error" });

        }
    }


    createProduct = async (item) => {
        console.log("createProduct")
        // for (let key in item) if (item[key] === '') item[key] = null;

        if (item.parent === '') item.parent = null;
        if (item.ingredients === '') item.ingredients = [];

        try {
            const res = await ConnectionManager.GetInstance().GetClient().post(`/catalog`, {
                ...item,
            });
            this.fetchCatalog(/* MenuStore.editableMenu?.[0]?.integrationId */);
            MsgStore.showMsg({ value: "Продукт в каталоге успешно создан", status: "ok" });

            return res;
        } catch (error) {
            console.error("ошибка createProduct", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка создания продукта в каталоге", status: "error" });
        }
    }


    createModificator = async (item) => {
        console.log("createProduct")
        for (let key in item) if (item[key] === '') item[key] = null;

        try {
            const res = await ConnectionManager.GetInstance().GetClient().post(`/catalog`, item);
            this.fetchCatalog(/* MenuStore.editableMenu?.[0]?.integrationId */);
            MsgStore.showMsg({ value: "Продукт в каталоге успешно создан", status: "ok" });

            return res;
        } catch (error) {
            console.error("ошибка createProduct", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка создания продукта в каталоге", status: "error" });
        }
    }

    async deleteCatalogItem(id) {
        // const id = AdminStore.findParentDatasetId(e);
        try {
            const res = await ConnectionManager.GetInstance().GetClient().delete(`/catalog/${id}`);
            this.setCatalog(
                this.catalog.filter((item) => {
                    if (item.id !== id) return true
                    return false
                })
            )

            MsgStore.showMsg({ value: "Компонент каталога успешно удален", status: "ok" });
            return res.data;
        } catch (error) {
            console.error("ошибка deleteCatalogItem", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка удаления компонента в каталоге", status: "error" });
        }
    }

    getCatalogItemById(id) {
        let catalogItem = this.catalog.filter((elem) => elem.id === id)
        console.log('getCatalogItemById', catalogItem[0])
        return catalogItem[0]
    }


    updateCatalogItem = async (item) => {
        for (let key in item) if (item[key] === '') item[key] = null;

        // item = {
        //     ...item,
        //     weight: weight,
        //     energyAmount: energyAmount,
        //     energyFullAmount: energyFullAmount,
        //     fatAmount: fatAmount,
        //     fatFullAmount: fatFullAmount,
        //     proteinsAmount: proteinsAmount,
        //     proteinsFullAmount: proteinsFullAmount,
        //     carbohydratesAmount: carbohydratesAmount,
        //     carbohydratesFullAmount: carbohydratesFullAmount,
        // }

        try {
            console.debug("updateCatalogItem", item)
            const res = await ConnectionManager.GetInstance().GetClient().put(`/catalog`, { ...item });
            if (MenuStore.editableMenu?.[0]) this.fetchCatalog(MenuStore.editableMenu?.[0]?.integrationId);
            MsgStore.showMsg({ value: "Компонент каталога обновлен", status: "ok" });

            this.setUpdateItemInCatalog(item);

            return res;
        } catch (error) {
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка обновления компонента в каталоге", status: "error" });
            console.error("ошибка updateCatalogItem", error.message)
        }
    }

}

export default new CatalogStore();
