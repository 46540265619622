import React from 'react';

import styles from './Item.module.scss';

import item_placeholder from '../../../img/icons/item_placeholder.svg';

import DnD from '../../../scripts/DnD';
import MenuStore from '../../../store/MenuStore';
import AdminStore from '../../../store/AdminStore';
import classNames from 'classnames';
const dnd = new DnD();

const Item = ({
    data,
    onUpdateHandler = () => { },
    onDeleteHandler = () => { },
    showBtn = true
}) => {
    const {
        name,
        id,
        // parent = null,
        // images,
        type,
        description
    } = data

    const dragStartHandler = (e) => {
        dnd.dragStartHandler(e)
    }

    const dragEndHandler = (e) => {
        dnd.dragEndHandler(e);
    }

    const dragOverHandler = (e) => {
        e.preventDefault()
        dnd.dragOverHandler(e);
    }

    const dragDropHandler = (e) => {
        dnd.dragDropHandler(e);
        dnd.highlightDropElement(e);

        dnd.highlightDropElementOnSort(e);
    }

    let itemClass
    switch (type) {
        case 1: itemClass = `${styles.product_item} ${styles.item} product_item`; break;
        case 2: itemClass = `${styles.group_item} ${styles.item} group`; break;
        case 3: itemClass = `${styles.modifier_item} ${styles.item} modifier_item`; break;
        case 4: itemClass = `${styles.modifier_group_item} ${styles.item} modifier_group`; break;
        default: break;
    }

    // let isShowTrash = false
    // if (MenuStore.editableMenuIntegrationType === 0) isShowTrash = true;
    // if (type === 1) isShowTrash = true;
    // if (type === 2) isShowTrash = true;

    let isShowTrash = true;


    return (
        <div
            className={classNames(itemClass, { [styles.table]: AdminStore.tableView })}
            data-id={id}

            data-itemside={"mid"}

            draggable={true}

            onDragStart={(e) => dragStartHandler(e)}
            onDragOver={(e) => dragOverHandler(e)}
            onDragEnd={(e) => dragEndHandler(e)}
            onDrop={(e) => dragDropHandler(e)}
        >
            {
                !AdminStore.tableView &&
                (type === 1 || type === 3) &&
                <div className={styles.item_image}>
                    {data?.images?.length > 0 ?
                        <img src={data.images?.[0]} alt={name}></img>
                        :
                        <img className={styles.item_placeholder} src={item_placeholder} alt={'item_placeholder'}></img>
                    }
                </div>
            }

            <div className={styles.item_text}>
                <div className={styles.title}>
                    {name}
                </div>
                <div className={styles.description}>
                    {description?.length > 40 ? description.slice(0, 45).concat("...") : description}
                </div>


                {data.index || data.index === 0 ?
                    <div className={styles.sort_index}>
                        {data.index}
                    </div>
                    :
                    null
                }

            </div>

            <div className={"item_dnd_left"} data-itemside={"left"} >
            </div>
            <div className={"item_dnd_right"} data-itemside={"right"} >
            </div>
            {showBtn &&
                <div className={styles.btn_wrapper}>
                    <button
                        className={styles.update_item_btn}
                        onClick={() => { onUpdateHandler(id) }}
                    >
                        <i className="fas fa-pencil-alt"></i>
                    </button>

                    {isShowTrash &&
                        <button
                            className={styles.delete_item_btn}
                            onClick={() => { onDeleteHandler(id) }}
                        >
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    }
                </div>
            }
        </div>
    );
};

export default Item;