import { observer } from 'mobx-react-lite';
import React from 'react';
import MenuStore from '../../../../../store/MenuStore';

import styles from './QueryItemList.module.scss';

const QueryItemList = ({ items, addonId }) => {
    const onChangeAmount = (item, e) => {
        MenuStore.setEditableMenuAdditionalItemAmount(addonId, item.id, e.target.value)
    }

    return (
        <div>
            {items.map(item => (
                <div key={item.key} className={styles.list_item}>
                    <div>
                        {item.name}
                    </div>
                    <div>
                        количество <input min={1} type="number" value={item.amount} onChange={(e) => { onChangeAmount(item, e) }} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default observer(QueryItemList);