import styles from '../forms.module.scss';

import { Field } from 'formik';

import CatalogStore from '../../../store/CatalogStore';
import MenuStore from '../../../store/MenuStore';

export default function ParentGroupDropBox({
    text = "Родительская группа",
    mode
}: {
    text: string;
    mode: "catalog" | "showcase"
}) {
    return (
        <div className={styles.popup_field}>
            <label htmlFor="name" className={styles.input_title}>
                {text}
            </label>

            <div className={styles.input_wrapper}>

                <Field className={styles.popup_input} as="select" name="parent">
                    <option key={0} value="">Корневая группа</option>
                    {mode === "catalog" &&
                        CatalogStore.catalog
                            .filter(item => item.type === 2 || item.type === 4)
                            .map(item => {
                                if (item.id === CatalogStore.catalogActiveItem.id) return ''
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                    }
                    {mode === "showcase" &&
                        MenuStore.editableMenu?.[0].items
                            .filter(item => item.type === 2)
                            .map(item => {
                                if (item.id === CatalogStore.catalogActiveItem.id) return ''
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                    }
                </Field>
            </div>

        </div>
    )
}
