import { useEffect } from "react";

import * as React from 'react';

import Menu, { RouteInfo } from "./Menu";
import User from "./User";
import "./Page.scss";
import ShowForm from '../components/forms/ShowForm';
import Message from '../components/Message';

import MenuStore from '../store/MenuStore';
import { observer } from 'mobx-react-lite';

const pages: RouteInfo[] = [
  {
    title: "Меню", path: "/menu",
    // routes: getPages(),
  },
  {
    title: "Виртуальные кассы", path: "/kassa",
    // routes: getPages(),
  },
  { title: "Рестораны", path: "/integrations" },
  { title: "Локальный каталог", path: "/catalog" },
  { title: "Список заказов", path: "/orderList" },
];

const getPages = (pages: RouteInfo[], title: string) => {
  return pages.map((page) => {
    if (page.title === title) {
      page.routes = [
        { title: "Создать меню", path: "newMenu" },
        ...MenuStore.showcase.map((menu) => {
          return { title: menu.name, path: menu.id }
        })
      ]
    }

    return page
  })
}

export type ButtonInfo = {
  icon?: string;
  text?: string;
  onClick?: (item: any) => void;
  buttons?: ButtonInfo[];
  visible?: boolean;
};

function Button({ icon, text, buttons = [], onClick }: ButtonInfo) {
  return buttons.length > 0 ? (
    <div className="multibutton">
      <div className="content">
        <i className={icon} />
        <span>{text}</span>
      </div>
      <div className="buttons">
        {buttons.map((button, i) => (
          <Button key={`bt_${i}`} {...button} />
        ))}
      </div>
    </div>
  ) : (
    <button onClick={onClick}>
      <div className="content">
        <i className={icon} />
        <span>{text}</span>
      </div>
    </button>
  );
}

const Page = ({
  children,
  title,
  buttons,
  className,
  routes = pages,
}: {
  title?: string;
  children?: React.ReactNode;
  buttons?: ButtonInfo[];
  className?: string;
  routes?: RouteInfo[];
}) => {
  useEffect(() => {
    document.title = "Foodof" + (title ? " | " + title : "");
  }, [title]);

  getPages(pages, "Меню");

  return (
    <>
      <ShowForm />
      <Message />
      <div className={`page ${className ?? ""}`}>
        <div className="navigation">
          <Menu pages={routes} logo={"Foodof"} title={title} />
        </div>
        <div className="header">
          <div className="page_btn_wrapper">
            <div className="buttons">
              {buttons && buttons.filter((x) => x.visible ?? true).map((button, i) => <Button key={`bt_${i}`} {...button} />)}
            </div>
          </div>
          <User />
        </div>
        <div className="content">
          {children}
        </div>
      </div>
    </>
  );
}

export default observer(Page);
