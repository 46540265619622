import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from "yup";
import { v4 as uuidv4 } from 'uuid';

import classnames from 'classnames';

import CatalogStore from '../../../store/CatalogStore';
import MenuStore from '../../../store/MenuStore';
import PopupStore from '../../../store/PopupStore';

import styles from '../forms.module.scss';
import { toJS } from 'mobx';

import TabPanel, { Item } from "devextreme-react/tab-panel";
import FormWrapper from '../FormWrapper';

import CustomField from '../formComponents/CustomField'
import CustomButton from '../formComponents/CustomButton';

import ParentGroupDropBox from '../formComponents/ParentGroupDropBox';

const ProductForm = ({
    onSubmit = () => console.log("ProductForm onSubmit"),
    mode = "catalog"
}) => {

    const [render, setRender] = useState({})

    const closeForm = () => { PopupStore.setActiveForm(''); }

    let items;
    switch (mode) {
        case "showcase":
            items = MenuStore.editableMenu?.[0]?.items;
            break;
        case "catalog":
            items = CatalogStore.catalog;
            break;
        default:
            break;
    }

    const onSubmitHandler = (values) => {
        if (values.modifiers) {
            values.modifiers = values.modifiers.filter(mod => mod.id !== '');
        }
        console.log("onSubmit", values)
        onSubmit(values).then(res => res.status === 202 && closeForm());
        // closeForm();
    }

    return (
        <Formik

            initialValues={{
                ...CatalogStore.catalogActiveItem,
                modifiers: CatalogStore.catalogActiveItem?.modifiers?.map(item => { return { ...item, uuid: uuidv4() } }),
                groupModifiers: CatalogStore.catalogActiveItem?.groupModifiers ? CatalogStore.catalogActiveItem?.groupModifiers?.map(item => { return { ...item, uuid: uuidv4() } }) : [],
                addModifier: {},
                addGroupModifier: {},
                addSize: {}
                // modifiers: []
            }}
            onSubmit={async values => {
                values.modifiers = values.modifiers.filter(mod => mod.id !== '');
                console.log("onSubmit", values)
                onSubmit(values);
                closeForm();
            }}

            validationSchema={Yup.object().shape({
                name: Yup.string().trim().required("Обязательно к заполнению"),
                price: Yup.number().typeError("Введите число").required("Обязательно к заполнению").positive("Цена не может быть отрицательной"),
                // index: Yup.number().typeError("Введите число").required("Обязательно к заполнению").integer("Должно быть целое число").min(0),
                // description: Yup.string()
                //     .required("Обязательно к заполнению"),
                // images: Yup.string()
                //     .required("Обязательно к заполнению"),
            })}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    // handleBlur,
                    // handleSubmit,
                    handleReset
                } = props;

                const deepDeleteModifierHandler = (item, type) => {

                    console.debug("delete Modifier Handler toJS(item)", toJS(item))

                    values.modifiers = values.modifiers.filter(elem => elem.uuid !== item.uuid);

                    const modifierTargetId = item.id;
                    let modifierCounter = 0;

                    for (let eMenuItem of MenuStore.editableMenu?.[0]?.items) {
                        for (let modifier of eMenuItem.modifiers) {
                            if (modifier.id === modifierTargetId) ++modifierCounter;
                            console.log(modifier, modifierCounter);
                        }
                    }

                    if (modifierCounter === 1) MenuStore.deleteItemFromEditableMenu(modifierTargetId);

                    setRender({});
                }

                const deleteModifierHandler = (item, type) => {
                    console.log("deleteModifierHandler")
                    values.modifiers = values.modifiers.filter(elem => elem.uuid !== item.uuid);
                    setRender({});

                }

                const deleteModifierFromGroupHandler = (id, groupModifier) => {
                    console.log("deleteModifierFromGroupHandler", id, groupModifier)

                    groupModifier.childModifiers = groupModifier.childModifiers.filter((item) => item.id !== id)
                    setRender({});
                }

                const deleteGroupModifierHandler = (item) => {
                    console.debug("deleteGroupModifierHandler toJS(item)", toJS(item))
                    values.groupModifiers = values.groupModifiers.filter(elem => elem.uuid !== item.uuid);
                    setRender({});
                }

                const deleteSizeHandler = (size) => {
                    values.sizes = values.sizes.filter(item => item.id !== size.id);
                    setRender({});
                }

                console.log("form values", values)

                let tabButtons = [
                    { id: 1, name: "Продукт", tabName: "main", integrationType: [0, 1, 2, 3] },
                    { id: 2, name: "Размеры", tabName: "sizes", integrationType: [0, 1, 2, 3] },
                    { id: 3, name: "Дополнительно", tabName: "details", integrationType: [0, 1, 2, 3] },
                    { id: 4, name: "Модификаторы", tabName: "modifiers", integrationType: [0, 1, 2, 3] },
                    { id: 5, name: "Групповые модификаторы", tabName: "groupModifiers", integrationType: [0, 1, 2, 3] },
                ]

                const emptyTabDisable = (name) => {
                    if (![0].includes(MenuStore.editableMenuIntegrationType) && values?.[name]?.length === 0) tabButtons = tabButtons.filter(button => button.tabName !== name)
                }

                emptyTabDisable("sizes");
                emptyTabDisable("modifiers");
                emptyTabDisable("groupModifiers");

                const customFieldProps = {
                    values,
                    errors,
                    touched
                }

                return (
                    <FormWrapper title={"Продукт"}>

                        {/* <Form className={classnames(styles.popup_container, styles.active)}> */}
                        <Form>
                            <TabPanel focusStateEnabled={false} >

                                <Item title="Продукт" icon="floppy">
                                    <>
                                        <div className={classnames(styles.form_fields, styles.product_form)}>

                                            <CustomField name={"name"} label={"Название продукта"} customFieldProps={props} isRequired={true} />
                                            <CustomField name={"description"} label={"Описание продукта"} customFieldProps={props} isRequired={true} />

                                            <CustomField
                                                name={"price"}
                                                label={"Цена продукта"}
                                                customFieldProps={props}
                                                isRequired={true}

                                                type="number"
                                                min="0"
                                            />

                                            {mode === "showcase" &&
                                                <CustomField
                                                    label={"Индекс для сортировки"}
                                                    name={"index"}

                                                    type="number"
                                                    min="0"
                                                    customFieldProps={props}
                                                />
                                            }

                                            <CustomField name={"ingredients[0]"} label={"Список ингредиентов"} customFieldProps={props} />
                                            <CustomField name={"measure"} label={"Единица измерения"} customFieldProps={props} />

                                            <CustomField
                                                name={"preparing"}
                                                label={"Время приготовления"}
                                                customFieldProps={props}

                                                type={"time"}
                                                step="2"
                                            />

                                            <CustomField name={"images[0]"} label={"Изображение для продукта"} customFieldProps={props} />
                                            <CustomField name={"currency"} label={"Валюта для расчета"} customFieldProps={props} />
                                            <ParentGroupDropBox mode={mode} />

                                        </div>
                                    </>
                                </Item>


                                <Item title="Размеры" icon="floppy">
                                    <>
                                        <div className={classnames(styles.form_fields, styles.product_form)}>
                                            <div className={styles.modifiers_wrapper}>

                                                <div className={classnames(styles.modifier, styles.popup_field)} >
                                                    <div>Название размера</div>
                                                    <div>Цена</div>
                                                </div>
                                                {values.sizes?.length > 0 &&
                                                    < FieldArray name={`sizes`} render={() => values.sizes
                                                        ?.map((size, i) => {
                                                            return (
                                                                <div className={styles.modifier} key={size.id}>
                                                                    <Field type="text" name={`sizes.${i}.name`} ></Field>
                                                                    <Field type="number" min="0" name={`sizes.${i}.price`} ></Field>
                                                                    {[0].includes(MenuStore.editableMenuIntegrationType) &&
                                                                        <button className={styles.add_modifier_btn} type="button" onClick={() => { deleteSizeHandler(size) }} >
                                                                            -
                                                                        </button>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        )} />
                                                }

                                                {[0].includes(MenuStore.editableMenuIntegrationType) &&
                                                    <>
                                                        <div className={styles.modifier}>
                                                            Добавить размер
                                                        </div>
                                                        <div className={styles.modifier}>
                                                            <Field type="text" min="0" name={`addSize.name`}></Field>
                                                            <Field type="number" min="0" name={`addSize.price`}></Field>

                                                            <button className={styles.add_modifier_btn} type="button"
                                                                onClick={() => {
                                                                    values.sizes.push({
                                                                        id: uuidv4(), //!!! iiko transport пошлет
                                                                        name: values.addSize.name,
                                                                        price: values.addSize.price,
                                                                    });
                                                                    values.addSize = {
                                                                        id: uuidv4(), name: '', price: 0,
                                                                    };
                                                                    setRender({});
                                                                }} >
                                                                +
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                </Item>


                                <Item title="Модификаторы" icon="floppy">
                                    <Modifiers
                                        values={values}
                                        items={items}
                                        mode={mode}
                                        deepDeleteModifierHandler={deepDeleteModifierHandler}
                                        deleteModifierHandler={deleteModifierHandler}
                                        setRender={setRender}
                                    />
                                </Item>

                                <Item title="Групповые модификаторы" icon="floppy">
                                    <GroupModifiers
                                        values={values}
                                        items={items}

                                        deleteGroupModifierHandler={deleteGroupModifierHandler}
                                        deleteModifierFromGroupHandler={deleteModifierFromGroupHandler}

                                        setRender={setRender}
                                    />
                                </Item>

                                <Item title="Дополнительно" icon="floppy">
                                    <div className={classnames(styles.form_fields, styles.product_form)}>

                                        <CustomField name={"weight"} label={"Вес продукта"} type="number" customFieldProps={props} />

                                        <div className={styles.popup_field}></div>

                                        <CustomField name={"energyFullAmount"} label={"Энергетическая ценность (ккал)"} type="number" customFieldProps={props} />
                                        <CustomField name={"proteinsFullAmount"} label={"Белки (г)"} type="number" customFieldProps={props} />
                                        <CustomField name={"fatFullAmount"} label={"Жиры (г)"} type="number" customFieldProps={props} />
                                        <CustomField name={"carbohydratesFullAmount"} label={"Углеводы (г)"} type="number" customFieldProps={props} />

                                        <div className={styles.popup_field}></div>
                                        <div className={styles.popup_field}>на 100 г продукта:</div>

                                        <CustomField name={"energyAmount"} label={"Энергетическая ценность (ккал)"} type="number" customFieldProps={props} />
                                        <CustomField name={"proteinsAmount"} label={"Белки (г)"} type="number" customFieldProps={props} />
                                        <CustomField name={"fatAmount"} label={"Жиры (г)"} type="number" customFieldProps={props} />
                                        <CustomField name={"carbohydratesAmount"} label={"Углеводы (г)"} type="number" customFieldProps={props} />

                                    </div>
                                </Item>

                            </TabPanel>

                        </Form >

                        <CustomButton
                            onClick={() => onSubmitHandler(values)}
                            disabled={isSubmitting}
                            icon={"dx-icon dx-icon-floppy"}
                            text={"Сохранить"}
                        />

                    </FormWrapper>

                );
            }}
        </Formik >)
};

export default observer(ProductForm);


const Modifiers = ({
    values,
    deepDeleteModifierHandler,
    deleteModifierHandler,
    setRender,
    items
}) => {


    const getModifierOpTionArray = (items) => {
        const modifiersOptionsArr = items
            .filter(item => {
                if (item === '') return false
                for (let modifier of values.modifiers) {
                    if (modifier.id === item.id) return false;
                }
                if (item.type !== 3) return false;
                if (item.id === CatalogStore.catalogActiveItem.id) return false
                return true;
            })
            .map(item => {
                return <option key={item.id} value={item.id}>{item.name}</option>
            })

        return modifiersOptionsArr;
    }




    const modifiersOptionsArr = getModifierOpTionArray(items);

    return (
        <div className={styles.product_form}>
            <div className={styles.modifier} >
                <div>Модификатор</div>
                <div>Цена</div>
                <div>min</div>
                <div>max</div>
            </div>
            <div className={styles.modifiers_wrapper}>
                {values.modifiers?.length > 0 &&
                    < FieldArray name="modifiers" render={() => values.modifiers
                        ?.map((item, i) => <Modifier
                            key={item.id}
                            i={i}
                            item={item}
                            items={items}
                            deepDeleteModifierHandler={deepDeleteModifierHandler}
                            deleteModifierHandler={deleteModifierHandler} />)}
                    />
                }
                {modifiersOptionsArr?.length > 0 && [0].includes(MenuStore.editableMenuIntegrationType) &&

                    <div className={styles.modifier}>
                        <Field className={styles.popup_input} as="select" name={`addModifier.id`}>
                            <option key={0} value="">Без добавок</option>
                            {modifiersOptionsArr}
                        </Field>
                        <Field type="number" min="0" name={`addModifier.price`}></Field>
                        <Field type="number" min="0" name={`addModifier.minAmount`}></Field>
                        <Field type="number" min="0" name={`addModifier.maxAmount`}></Field>
                        <button className={styles.add_modifier_btn} type="button"
                            onClick={() => {
                                if (!values.addModifier.id) return;

                                // const editableMenuItem = MenuStore.getItemFromEditableMenuById(values.addModifier.id)

                                values.modifiers.push({
                                    uuid: uuidv4(),
                                    id: values.addModifier.id,
                                    // item: editableMenuItem.item,
                                    price: values.addModifier.price ? values.addModifier.price : 30,
                                    minAmount: values.addModifier.minAmount ? values.addModifier.minAmount : 0,
                                    maxAmount: values.addModifier.maxAmount ? values.addModifier.maxAmount : 1
                                });
                                values.addModifier = {}
                                setRender({});
                            }} >
                            +
                        </button>
                    </div>
                }
            </div>
        </div>

    );
};


const Modifier = ({
    item,
    items,
    i,
    deepDeleteModifierHandler,
    deleteModifierHandler,
    mode
}) => {
    return (
        <div className={styles.modifier} key={i}>
            <div className={styles.modifier_title}>
                {/*                 {MenuStore.editableMenu?.[0]?.items */}
                {items
                    .filter(menuItem => menuItem.id === item.id)
                    .map(item => {
                        if (item.id === CatalogStore.catalogActiveItem.id) return ''
                        return <div key={item.id} value={item.id}>{item.name}</div>
                    })}
            </div>
            {[0].includes(MenuStore.editableMenuIntegrationType) ?
                <>
                    <Field type="number" min="0" name={`modifiers.${i}.price`}></Field>
                    <Field type="number" min="0" name={`modifiers.${i}.minAmount`}></Field>
                    <Field type="number" min="0" name={`modifiers.${i}.maxAmount`}></Field>
                </>
                :
                <>
                    <div>{item.price}</div>
                    <div>{item.minAmount}</div>
                    <div>{item.maxAmount}</div>
                </>
            }
            {[0].includes(MenuStore.editableMenuIntegrationType) &&
                <button className={styles.add_modifier_btn} type="button" onClick={() => { deleteModifierHandler(item, 3) }} >
                    -
                </button>
            }
            {mode === "showcase" &&
                <button className={styles.add_modifier_btn} type="button" onClick={() => { deepDeleteModifierHandler(item, 3) }} >
                    <i className="fas fa-trash-alt"></i>
                </button>
            }
        </div>
    );
};



const GroupModifiers = ({
    values,
    items,

    deleteGroupModifierHandler,
    deleteModifierFromGroupHandler,

    setRender,
}) => {
    const groupModifiersOptionsArr = items
        .filter(item => {
            if (item === '') return false
            for (let groupModifier of values.groupModifiers) {
                if (groupModifier.id === item.id) return false;
            }
            if (item.type !== 4) return false;
            if (item.id === CatalogStore.catalogActiveItem.id) return false
            return true;
        })
        .map(item => {
            return <option key={item.id} value={item.id}>{item.name}</option>
        })

    const modifiersOptionsInModifiersGroup = (id, groupModifier, items) => {

        let data = JSON.parse(JSON.stringify(items))

        const recursionChecking = (elements, id) => {
            for (let elem of elements) {
                if (elem.del) continue
                if (elem.id === id) { elem.del = true; continue }
                if (elem.parent === id) {
                    elem.del = true;
                    recursionChecking(elements, elem.id);
                }
            }
        }
        recursionChecking(data, id);

        return data
            .filter(item => {
                if (item === '') return false

                if (groupModifier.childModifiers) {
                    for (let childModifier of groupModifier.childModifiers) {
                        if (childModifier.id === item.id) return false
                    }
                }

                if (item.type !== 3) return false;
                if (item.del !== true) return false;
                if (item.id === CatalogStore.catalogActiveItem.id) return false
                return true;
            })
            .map(item => {
                return <option key={item.id} value={item.id} item={item.item}>{item.name}</option>
            })
    }

    return (
        <div className={classnames(styles.product_form)}>
            <div className={classnames(styles.modifier, /* styles.group_modifiers_wrapper */)} >
                <div>Групповой модификатор</div>
                <div></div>
                <div>min</div>
                <div>max</div>
                <div></div>
            </div>

            {values.groupModifiers?.length > 0 &&
                <GroupModifiersList
                    values={values}
                    items={items}
                    modifiersOptionsInModifiersGroup={modifiersOptionsInModifiersGroup}
                    deleteGroupModifierHandler={deleteGroupModifierHandler}
                    deleteModifierFromGroupHandler={deleteModifierFromGroupHandler}
                    setRender={setRender}
                />
            }

            {groupModifiersOptionsArr?.length > 0 && [0].includes(MenuStore.editableMenuIntegrationType) &&
                <div className={classnames(styles.modifier, styles.group_modifiers_wrapper)}>
                    <Field className={styles.popup_input} as="select" name={`addGroupModifier.id`}>
                        <option key={0} value="">Без добавок</option>
                        {groupModifiersOptionsArr}
                    </Field>
                    <Field type="number" min="0" name={`addGroupModifier.minAmount`}></Field>
                    <Field type="number" min="0" name={`addGroupModifier.maxAmount`}></Field>
                    <button className={styles.add_modifier_btn} type="button"
                        onClick={() => {
                            if (!values.addGroupModifier.id) return;

                            // const editableMenuItem = MenuStore.getItemFromEditableMenuById(values.addGroupModifier.id)

                            values.groupModifiers.push({
                                uuid: uuidv4(),
                                id: values.addGroupModifier.id,
                                // item: editableMenuItem.item,
                                minAmount: values.addGroupModifier.minAmount ? values.addGroupModifier.minAmount : 1,
                                maxAmount: values.addGroupModifier.maxAmount ? values.addGroupModifier.minAmount : 1
                            });
                            setRender({});
                        }} >
                        +
                    </button>

                </div>
            }
        </div>
    );
};


const GroupModifiersList = ({
    values,
    items,

    deleteGroupModifierHandler,
    deleteModifierFromGroupHandler,

    modifiersOptionsInModifiersGroup,
    groupModifiersOptionsArr,

    setRender,
}) => {



    return (
        <>
            < FieldArray name="groupModifiers" render={() => values.groupModifiers
                ?.map((groupModifier, i) => {
                    const modifiermodifiersOptionsInModifiersGroup = modifiersOptionsInModifiersGroup(groupModifier.id, groupModifier, items)
                    return (
                        <div key={groupModifier.id} >
                            <div className={styles.group_modifiers_wrapper} key={groupModifier.id}>
                                <div className={styles.modifier_title}>

                                    {items
                                        .filter(menuItem => menuItem.id === groupModifier.id)
                                        .map(item => {
                                            if (item.id === CatalogStore.catalogActiveItem.id) return ''
                                            return <div key={item.id} value={item.id}>{item.name}</div>
                                        })}
                                </div>
                                {[0].includes(MenuStore.editableMenuIntegrationType) ?
                                    <>
                                        <Field type="number" min="0" name={`groupModifiers.${i}.minAmount`}></Field>
                                        <Field type="number" min="0" name={`groupModifiers.${i}.maxAmount`}></Field>
                                    </>
                                    :
                                    <>
                                        <div>{groupModifier.minAmount}</div>
                                        <div>{groupModifier.maxAmount}</div>
                                    </>
                                }

                                {[0].includes(MenuStore.editableMenuIntegrationType) &&
                                    <button className={styles.add_modifier_btn} type="button"
                                        onClick={() => { deleteGroupModifierHandler(groupModifier) }}
                                    >
                                        -
                                    </button>
                                }
                            </div>

                            {/* внутри листа дочерние модифаеры */}
                            <div className={styles.child_modifiers}>
                                {/* !!! child modifiers */}
                                <div className={styles.modifiers_wrapper}>
                                    {values.groupModifiers?.length > 0 &&
                                        < FieldArray name={`groupModifiers.${i}.childModifiers`} render={() => values.groupModifiers[i]?.childModifiers
                                            ?.map((item, n) => {
                                                return (
                                                    <div className={styles.modifier} key={item.id}>
                                                        <div className={styles.modifier_title}>
                                                            {items
                                                                .filter(menuItem => menuItem.id === item.id)
                                                                .map(item => {
                                                                    if (item.id === CatalogStore.catalogActiveItem.id) return ''
                                                                    return <div key={item.id} value={item.id}>{item.name}</div>
                                                                })}
                                                        </div>
                                                        {[0].includes(MenuStore.editableMenuIntegrationType) ?
                                                            <>
                                                                <Field type="number" min="0" name={`groupModifiers.${i}.childModifiers.${n}.price`}></Field>
                                                                <Field type="number" min="0" name={`groupModifiers.${i}.childModifiers.${n}.minAmount`}></Field>
                                                                <Field type="number" min="0" name={`groupModifiers.${i}.childModifiers.${n}.maxAmount`}></Field>
                                                            </>
                                                            :
                                                            <>
                                                                <div>{item.price}</div>
                                                                <div>{item.minAmount}</div>
                                                                <div>{item.maxAmount}</div>
                                                            </>
                                                        }
                                                        {[0].includes(MenuStore.editableMenuIntegrationType) &&
                                                            <button className={styles.add_modifier_btn} type="button" onClick={() => { deleteModifierFromGroupHandler(item.id, groupModifier) }} >
                                                                -
                                                            </button>
                                                        }
                                                    </div>
                                                )
                                            }
                                            )} />
                                    }
                                    {modifiermodifiersOptionsInModifiersGroup?.length > 0 &&
                                        <div className={styles.modifier}>
                                            <Field className={styles.popup_input} as="select" name={`addModifier.id`}>
                                                <option key={0} value="">Без добавок</option>
                                                {modifiermodifiersOptionsInModifiersGroup}
                                            </Field>
                                            <Field type="number" min="0" name={`addModifier.price`}></Field>
                                            <Field type="number" min="0" name={`addModifier.minAmount`}></Field>
                                            <Field type="number" min="0" name={`addModifier.maxAmount`}></Field>
                                            <button className={styles.add_modifier_btn} type="button"
                                                onClick={() => {
                                                    if (!values.addModifier.id) return;
                                                    if (!values.groupModifiers[i].childModifiers) values.groupModifiers[i].childModifiers = []

                                                    // const editableMenuItem = MenuStore.getItemFromEditableMenuById(values.addModifier.id)

                                                    values.groupModifiers[i].childModifiers.push({
                                                        uuid: uuidv4(),
                                                        id: values.addModifier.id,
                                                        // item: editableMenuItem.item,
                                                        price: values.addModifier.price ? values.addModifier.price : 30,
                                                        minAmount: values.addModifier.minAmount ? values.addModifier.minAmount : 0,
                                                        maxAmount: values.addModifier.maxAmount ? values.addModifier.maxAmount : 1
                                                    });
                                                    values.addModifier = {}
                                                    setRender({});
                                                }} >
                                                +
                                            </button>
                                        </div>
                                    }
                                </div>
                                {/* / child modifiers */}
                            </div>
                        </div>
                    )
                }

                )} />
            {groupModifiersOptionsArr?.length > 0 &&
                <div className={classnames(styles.modifier, styles.group_modifiers_wrapper)}>
                    <Field className={styles.popup_input} as="select" name={`addGroupModifier.id`}>
                        <option key={0} value="">Без добавок</option>
                        {groupModifiersOptionsArr}
                    </Field>
                    {/* <Field type="number" min="0" name={`addGroupModifier.price`}></Field> */}
                    <Field type="number" min="0" name={`addGroupModifier.minAmount`}></Field>
                    <Field type="number" min="0" name={`addGroupModifier.maxAmount`}></Field>
                    <button className={styles.add_modifier_btn} type="button"
                        onClick={() => {
                            if (!values.addGroupModifier.id) return;

                            values.groupModifiers.push({
                                uuid: uuidv4(),
                                id: values.addGroupModifier.id,
                                // item: editableMenuItem.item,
                                minAmount: values.addGroupModifier.minAmount ? values.addGroupModifier.minAmount : 1,
                                maxAmount: values.addGroupModifier.maxAmount ? values.addGroupModifier.minAmount : 1
                            });
                            setRender({});
                        }} >
                        +
                    </button>

                </div>
            }
        </>

    );
};



