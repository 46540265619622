import { defaultOperators, Field, } from 'react-querybuilder';

export const fields: Field[] = [
    {
        name: 'amount',
        label: 'Сумма заказа',
        inputType: 'number',
        dataType: 'double',
        // Supported values are "integer", "double", "string", "date", "datetime", and "boolean"
    },
];

export const getOperators = (fieldName: string) => {
    const field = fields.find(fld => fld.name === fieldName);
    const { dataType } = field

    // Supported values are "integer", "double", "string", "date", "datetime", and "boolean"
    if (dataType === "integer" || dataType === "double") {
        return [
            ...defaultOperators.filter(op => ['=', '!=', '<', '<=', '>', '>='].includes(op.name)),
            // { name: '<', label: 'less than' },
            // { name: '<=', label: 'less than or equal to' },
            // { name: '>', label: 'greater than' },
            // { name: '>=', label: 'greater than or equal to' },
            // ...defaultOperators.filter(op => ['null', 'notNull'].includes(op.name)),
        ];

    } else if (dataType === "string") {
        return [
            { name: '=', label: 'is' },
            { name: '!=', label: 'is not' },
            ...defaultOperators.filter(op =>
                [
                    'contains',
                    'beginsWith',
                    'endsWith',
                    'doesNotContain',
                    'doesNotBeginWith',
                    'doesNotEndWith',
                    'null',
                    'notNull',
                    'in',
                    'notIn',
                ].includes(op.name)
            ),
        ];
    } else if (dataType === "date") {
        return [
            { name: '=', label: 'on' },
            { name: '!=', label: 'not on' },
            { name: '<', label: 'before' },
            { name: '<=', label: 'on or before' },
            { name: '>', label: 'after' },
            { name: '>=', label: 'on or after' },
            ...defaultOperators.filter(op => ['null', 'notNull'].includes(op.name)),
        ];
    } else {
        return defaultOperators;
    }
}

export const translations = {
    "addRule": {
        "label": "+Правило",
        "title": "Add rule"
    },
    "addGroup": {
        "label": "+Группа",
        "title": "Add group"
    },
}
