import styles from '../forms.module.scss';

export default function CustomButton({
    onClick,
    disabled,
    icon,
    text,
    type
}: {
    onClick?: () => {},
    disabled?: boolean,
    icon?: string,
    text?: string,
    type: "button" | "submit"
}) {
    return (
        <div className={styles.btn_wrapper}>
            <button
                onClick={onClick}
                className={styles.login_btn}
                type="submit"
                disabled={disabled}>
                <i className={icon}></i>
                {text}
            </button>
        </div>
    );
}

