import AdminStore from "../store/AdminStore";
import CatalogStore from "../store/CatalogStore";
import MenuStore from "../store/MenuStore";

import { v4 as uuidv4 } from 'uuid';


class DnD {
    dragStartHandler = (e) => {
        //опрелеляем тип окна, откуда берем айтем
        const fieldTypeElem = e.target.closest("[data-fieldtype]");
        const fieldType = fieldTypeElem.dataset.fieldtype;
        console.log("dragStartHandler fieldType", fieldType);
        MenuStore.setActiveDnDStartField(fieldType);

        // console.log("[data-fieldtype='showcase']", e.target.closest("[data-fieldtype='showcase']"))

        // если тип поля определился, как Showcase, мы берем айтем из другого стейта (Menu, а не Catalog)
        // когда перетаскивается в то же окно (это должна быть сортировка)
        if (fieldType === "showcase") {
            const activeItem = MenuStore.editableMenu?.[0].items.filter(item => e.target.dataset.id === item.id)[0];
            MenuStore.setActiveDnDItem(activeItem);//так компоненты удаляются из оригинального списка, это будет нужно в сортировке?
            console.log("dragStartHandler e.target", e.target.dataset.id, activeItem.id, MenuStore.activeDnDItem.id)
            // console.log("if (fieldType === 'showcase') dragStartHandler в showcase", MenuStore.activeDnDItem, activeItem)
            return
        } else {
            const activeItem = CatalogStore.catalog.filter(item => e.target.dataset.id === item.id)[0];
            MenuStore.setActiveDnDItem({ ...activeItem });
        }
    }

    dragEnterHandler = (e) => {

    }


    dragLeaveHandler = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

    }

    dragEndHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    dragOverHandler = (e) => {

    }

    dragDropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();


        // можно отпустить дроп только в showcase
        if (!e.target.closest("[data-fieldtype='showcase']")) return

        // ищем близжайшую обертку группы и берем с нее id
        const targetWrapper = e.target.closest('[data-type="2"]');
        let targetParentGroupId = targetWrapper.dataset.id;

        // багфикс, если мы выделяем несколько айтемов через text_highlight и перетаскиваем
        if (!MenuStore.activeDnDItem.name) return
        if (MenuStore.activeDnDItem.id === targetParentGroupId) return
        if (!e.target.closest('[data-type]')) return


        // костыль, заменяет '' на null
        if (targetParentGroupId === '') targetParentGroupId = null;

        // определяем тип окна, куда дропаем
        const fieldTypeElem = e.target.closest("[data-fieldtype]");
        const fieldType = fieldTypeElem.dataset.fieldtype;

        // Таргетная обертка, ее Id, и айтем из менюхи, который совпадает с id
        const dropTargetElemId = e.target.closest("[data-id]").dataset.id;
        let dropTargetItem = MenuStore.getItemFromEditableMenuById(dropTargetElemId)

        // если мы дропаем в пустую область, то присваеваем индекс 
        if (!dropTargetItem) dropTargetItem = { index: 1 }

        // можно удалить??
        // блокируем перетаскивание catalog -> catalog          и showcase -> catalog
        if (MenuStore.activeDnDStartField === "showcase" && fieldType === "catalog") return
        if (fieldType === MenuStore.activeDnDStartField && fieldType === "catalog") return

        // определяем, на какую половину айтема произошел дроп
        const itemSide = e.target.closest('[data-itemside]').dataset.itemside

        MenuStore.changeSortIndex(targetParentGroupId);

        const item = MenuStore.activeDnDItem?.item;
        const parent = targetParentGroupId;

        // если мы тянем из showcase => showcase
        if (MenuStore.activeDnDStartField === 'showcase') {




            // если перетаскиваем карточку группы или группы модификаторов

            const showcaseToShowcaseMoveItem = () => {
                const id = MenuStore.activeDnDItem?.id;

                // получаем массив без детей
                let ArrOfParents = AdminStore.parentIdRecursionChecker(id, MenuStore.editableMenu?.[0].items)
                // target должен содержаться в массиве data
                if (targetParentGroupId === null || ArrOfParents.filter(elem => elem.id === parent).length === 1) {

                    if (dropTargetItem.index === 0) dropTargetItem.index = 1

                    MenuStore.deleteItemFromEditableMenu(MenuStore.activeDnDItem.id)
                    MenuStore.changeSortIndex(targetParentGroupId);

                    // если группа на одном уровне нужно просто менять индекс, если на разных уровнях, нужно менять еще и парент
                    switch (itemSide) {
                        case "right": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { /* parent, */ index: dropTargetItem.index }); break;
                        case "left": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { /* parent, */ index: dropTargetItem.index - 1 }); break;
                        case "root": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { parent, index: 99 }); break;
                        case "mid": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { parent, index: 99 }); break;
                        default: break;
                    }

                }
            }


            switch (MenuStore.activeDnDItem?.type) {
                case 1:
                    MenuStore.deleteItemFromEditableMenu(MenuStore.activeDnDItem.id)
                    MenuStore.changeSortIndex(targetParentGroupId);

                    switch (itemSide) {
                        case "right": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { item, parent, index: dropTargetItem.index }); break;
                        case "left": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { item, parent, index: dropTargetItem.index - 1 }); break;
                        case "root": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { item, parent, index: 99 }); break;
                        case "mid": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { item, parent, index: 99 }); break;
                        default: break;
                    }

                    break;
                case 2:
                    showcaseToShowcaseMoveItem();
                    break;
                case 3:
                    MenuStore.deleteItemFromEditableMenu(MenuStore.activeDnDItem.id)
                    MenuStore.changeSortIndex(targetParentGroupId);

                    switch (itemSide) {
                        case "right": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { /* item, */ parent, index: dropTargetItem.index }); break;
                        case "left": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { /* item, */ parent, index: dropTargetItem.index - 1 }); break;
                        case "root": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { /* item, */ parent, index: 99 }); break;
                        case "mid": MenuStore.pureAddItemToEditableMenu(MenuStore.activeDnDItem, { /* item, */ parent, index: 99 }); break;
                        default: break;
                    }
                    break;
                case 4:
                    showcaseToShowcaseMoveItem();
                    break;
                default:
                    break;
            }



            return
        }

        // если мы тянем из catalog => showcase
        if (MenuStore.activeDnDStartField === 'catalog') {

            let res = AdminStore.parentIdRecursionChecker(MenuStore.activeDnDItem.id, CatalogStore.catalog, { reverse: true })

            console.debug("dnd AdminStore.parentIdRecursionChecker", res)



            // если мы перетягиваем группу или группу модификаторов
            // !!!
            // проходим по цепочке детей
            // проходим по каждому товару в цепочке
            //копируем его с модификаторами

            const groupCopyFromCatalogToShowcase = () => {

                const deleteItem = (itemId, arr, newId) => {
                    for (let key in arr) {
                        const item = arr[key];
                        // console.log("deleteItem item", item)
                        if (item.foo) continue
                        if (item.id === itemId) {
                            item.foo = true;
                            item.item = arr[key].id;
                            item.newId = newId;
                            continue
                        }
                        if (item.parent === itemId) {
                            item.foo = true;
                            item.item = item.id;
                            item.newId = uuidv4();
                            item.newParent = newId;
                            deleteItem(item.id, arr, item.newId);
                        }
                    }
                }
                deleteItem(MenuStore.activeDnDItem.id, res, uuidv4());

                const cleaningItems = (arr) => {
                    for (let item of arr) {
                        if (item.del) delete item.del;
                        if (item.foo) delete item.foo;
                        if (item.newId) {
                            item.id = item.newId;
                            delete item.newId;
                        }
                        if (item.newParent) {
                            item.parent = item.newParent;
                            delete item.newParent;
                        } else {
                            item.parent = parent;
                        }
                    }
                    return arr;
                }
                cleaningItems(res);

                MenuStore.changeSortIndex(targetParentGroupId);
                res.forEach((elem, i) => MenuStore.addItemToEditableMenuWithMods(elem, { index: dropTargetItem.index }, {isReplaceId: false})) //!!! доделать копирование модификаторов
            }

            const itemCopyFromCatalogToShowcase = () => {

                MenuStore.deleteItemFromEditableMenu(MenuStore.activeDnDItem.id)
                MenuStore.changeSortIndex(targetParentGroupId);

                // доделать копирование модификаторов
                switch (itemSide) {
                    case "right": MenuStore.addItemToEditableMenuWithMods(MenuStore.activeDnDItem, { item: MenuStore.activeDnDItem.id, parent, index: dropTargetItem.index }); break;
                    case "left": MenuStore.addItemToEditableMenuWithMods(MenuStore.activeDnDItem, { item: MenuStore.activeDnDItem.id, parent, index: dropTargetItem.index - 1 }); break;
                    case "root": MenuStore.addItemToEditableMenuWithMods(MenuStore.activeDnDItem, { item: MenuStore.activeDnDItem.id, parent, index: 99 }); break;
                    case "mid": MenuStore.addItemToEditableMenuWithMods(MenuStore.activeDnDItem, { item: MenuStore.activeDnDItem.id, parent, index: 99 }); break;
                    default: break;
                }
            }


            switch (MenuStore.activeDnDItem.type) {
                case 1: //продукт
                    itemCopyFromCatalogToShowcase();
                    break;
                case 2: //группа
                    groupCopyFromCatalogToShowcase();
                    break;
                case 3: //модификатор
                    // if (MenuStore.editableMenuIntegrationType === 0) itemCopyFromCatalogToShowcase();
                    itemCopyFromCatalogToShowcase();
                    break;
                case 4: //группа модификаторов
                    // if (MenuStore.editableMenuIntegrationType === 0) groupCopyFromCatalogToShowcase();
                    groupCopyFromCatalogToShowcase();
                    break;
                default:
                    break;
            }
            return
        }
    }


    // логика с подсвечиванием дропа
    highlightEnterHandler = (e) => {
        e.preventDefault()
        if (!e.target.closest("[data-type='2']")) return
        if (e.target.closest("[data-fieldtype='showcase']")) {
            const highlightElem = e.target.closest("[data-type]")
            highlightElem.classList.add("active")
            // console.log("highlightEnterHandler", highlightElem)
        }
    }

    highlightLeaveHandler(e) {
        if (!e.target.dataset.type) return
        if (!e.target.closest("[data-type='2']")) return
        if (!e.target.closest("[data-fieldtype='showcase']")) return

        this.removeAllActiveClass(e);

        const highlightElem = e.target.closest("[data-type='2']")
        highlightElem.classList.remove("active")
        // console.log("highlightLeaveHandler", highlightElem)
    }

    highlightDropElement(e) {
        // если это не группа то выходим
        if (!e.target.closest("[data-type='2']")) return
        if (!e.target.closest("[data-fieldtype='showcase']")) return

        // this.removeAllActiveClass(e, "activeDndHighligthSort");

        // если это внутри showcase, то удаляем подсветку 
        if (e.target.closest("[data-fieldtype='showcase']")) this.removeAllActiveClass(e);
    }

    highlightOverElement(e) {
        e.preventDefault();
        //  если это не группа, то выходим
        if (!e.target.closest("[data-type='2']")) return;
        // если это не внутри showcase. то выходим
        if (!e.target.closest("[data-fieldtype='showcase']")) return;

        const highlightElem = e.target.closest("[data-type='2']");
        if (!highlightElem.classList.contains("active")) highlightElem.classList.add("active")
    }

    removeAllActiveClass(e, activeClass = "active") {
        if (!e.target.closest("[data-fieldtype='showcase']")) return
        const activeWrapper = e.target.closest("[data-fieldtype='showcase']")
        const allActiveItems = activeWrapper.querySelectorAll(`.${activeClass}`)
        for (let elem of allActiveItems) {
            elem.classList.remove(`${activeClass}`);
            // console.log("let elem of allActiveItems", elem)
        }
    }

    // !!! переписать
    highlightOverElementOnSort(e) {
        e.preventDefault();
        if (!e.target.closest("[data-fieldtype='showcase']")) return

        // если это не внутри showcase. то выходим
        if (!e.target.closest("[data-fieldtype='showcase']")) return;
        if (e.target.dataset.fieldtype === "showcase") return




        //  если это не группа, то выходим
        // if (!e.target.closest("[data-type='2']")) return;

        const highlightElem = e.target.closest("[data-itemside]");
        if (highlightElem.dataset.type === 2) return
        if (highlightElem.dataset.itemside === "root") return
        if (!highlightElem.classList.contains("activeDndHighligthSort")) highlightElem.classList.add("activeDndHighligthSort")
    }

    // !!! переписать
    highlightLeaveElementOnSort(e) {
        if (!e.target.closest("[data-fieldtype='showcase']")) return
        if (!e.target.closest("[data-itemside]")) return

        this.removeAllActiveClass(e, "activeDndHighligthSort");

        const highlightElem = e.target.closest("[data-itemside]")
        highlightElem.classList.remove("activeDndHighligthSort")
        // console.log("highlightLeaveHandler", highlightElem)

    }


    highlightDropElementOnSort(e) {
        if (!e.target.closest("[data-fieldtype='showcase']")) return


        this.removeAllActiveClass(e, "activeDndHighligthSort");
        // !!! костыль
        e.target.closest("[data-fieldtype='showcase']").classList.remove("activeDndHighligthSort")

    }
    // /логика с подсвечиванием дропа

    // подсвечивание левой/правой стороны айтема при сортировке
    // /подсвечивание левой/правой стороны айтема при сортировке

}

export default DnD;