import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import styles from './AuthPage.module.scss';

import AuthStore from '../../store/AuthStore';

import AuthForm from '../../components/AuthForm';
import Message from '../../components/Message';

const AuthPage = () => {
    const navigate = useNavigate();

    if (AuthStore.isAuth) navigate("/catalog");

    return (
        <>
            <Message />
            <div className={styles.container}>
                <AuthForm />
            </div>
        </>
    );
};

export default observer(AuthPage);