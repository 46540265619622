import React from 'react';
import styles from './IntegrationItem.module.scss';

import trash_icon from '../../../img/icons/trash_icon.svg';
import change_icon from '../../../img/icons/change_icon.svg';


const IntegrationItem = ({
    item,
    onUpdateHandler,
    onDeleteHandler
}) => {

    const { id } = item;

    return (
        <div className={styles.item} data-id={id}>
            <div className={styles.title}>
                {item.name}
                <br />
            </div>
            <div className={styles.description}>
                {item.type === 0 && "Локальный каталог"}
                {item.type === 1 && "IIKO Biz интеграция"}
                {item.type === 2 && "IIKO Transport интеграция"}

            </div>
            <div className={styles.description}>
                {item.paymentSystem?.isFiscal ? "фискализация обязательна" : ''}
            </div>
            <div className={styles.menu_item_wrapper}>
                Меню: {item?.items?.map(elem => <div className={styles.menu_item} key={elem.id}> {elem.name}</div>)}
            </div>
            <div className={styles.btn_wrapper}>
                <button className={styles.update_orgs_btn} onClick={() => { onUpdateHandler(id) }}>
                    <img src={change_icon} alt="" />
                    <div className={styles.btn_title}>
                        Изменить
                    </div>
                </button>
                <button className={styles.delete_orgs_btn} onClick={() => { onDeleteHandler(id) }}>
                    <img src={trash_icon} alt="" />
                    <div className={styles.btn_title}>
                        Удалить
                    </div>
                </button>
            </div>
        </div>
    );
};

export default IntegrationItem;