import classnames from 'classnames';
import { Field } from 'formik';
import { boolean } from 'yup';
import styles from '../forms.module.scss';


export default function CustomField({
    label = '',
    name,
    placeholder,
    type = "text",
    withError = false,
    isRequired = false,
    disabled = false,
    min,
    step,
    customFieldProps,
    as = ''
}: {
    label?: string;
    name: string;
    placeholder?: string;
    type?: "text" | "number";
    withError?: boolean;
    isRequired?: boolean;
    min?: string;
    step?: string;
    disabled?: boolean;
    customFieldProps?: any;
    as?: string;
}) {

    const {
        values,
        errors,
        touched
    } = customFieldProps

    return (
        <div className={styles.popup_field}>
            <label /* htmlFor="name" */ className={classnames(styles.input_title, isRequired && styles.required)}>
                {label}
            </label>
            <div className={styles.input_wrapper}>

                {disabled ?
                    <div className={classnames(styles.popup_input, styles.disabled)}>
                        {values[name]}
                    </div>
                    :
                    <>
                        <Field
                            className={styles.popup_input}
                            name={name}
                            placeholder={placeholder ? placeholder : label}
                            type={type}
                            min={min}
                            step={step}
                            as={as}
                        />
                        {withError &&
                            <>
                                {errors[name] && touched[name] && (
                                    <div className={styles.input_feedback}>{errors[name]}</div>
                                )}
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
};