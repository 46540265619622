import { observer } from "mobx-react-lite";
import { FC } from "react"
import KassaStore from "store/KassaStore";

import { kassa, taxationSystemLabels } from "types";

import styles from '../KassaPage.module.scss';

const KassaItem = ({
  item
}: {
  item: kassa
}) => {

  /* 
  name	"Kassa"
  id	"404403ee-a750-48e9-be13-883fb306e3ef"
  inn	"11123456789"
  taxationSystem	0
  */
  const isFiscalString = (isFiscal: boolean | null) => {
    switch (isFiscal) {
      case true: return "Да"
      case false: return "Нет"
      default: return '';
    }
  }

  return (
    <tr className={styles.item}>
      <td>{item.name}</td>
      <td>{item.id}</td>
      <td>{item.inn}</td>
      <td>{taxationSystemLabels[item.taxationSystem]}</td>
      <td>
        {isFiscalString(item.isFiscal)}
      </td>
      <td>
        <div className={styles.delete_btn_wrapper}>
          <button
            className={styles.delete_btn}
            onClick={() => {
              KassaStore.deleteKassa(item.id);
              console.log("table onClick", item.name)
            }}
          >
            Х
          </button>
        </div>
      </td>
    </tr>
  )
}

export default observer(KassaItem);
