import { makeAutoObservable } from "mobx";

import {ConnectionManager} from '../http/axios';

import MenuStore from "./MenuStore";
import MsgStore from "./MsgStore";
import {defaultIntegration} from "../types/const";

class AdminStore {

    integrationsList = [];

    activeIntegration = defaultIntegration;

    organisationList = [];

    catalogIsLoading = true;
    showcaseIsLoading = true;

    tableView = false;

    get integrationNamesDictionary() {
        let dictionary = {};
        for (let integration of this.integrationsList) {
            dictionary = { ...dictionary, [integration.id]: integration.name }
        }
        console.log(dictionary)
        return dictionary
    }

    constructor() {
        makeAutoObservable(this);
    }

    setIntegrationsList(integrationsList) {
        this.integrationsList = integrationsList;
    }

    setActiveIntegration(id) {
        this.activeIntegration = this.integrationsList.find(item => item.id === id);
        console.log("this.activeIntegration", this.activeIntegration);
    }

    resetActiveIntegration() {
        this.activeIntegration = defaultIntegration;
    }

    setOrganisationList(organisationList) {
        this.organisationList = organisationList;
    }

    setCatalogIsLoading(catalogIsLoading) {
        this.catalogIsLoading = catalogIsLoading;
    }

    setShowcaseIsLoading(showcaseIsLoading) {
        this.showcaseIsLoading = showcaseIsLoading
    }

    setTableView(tableView) {
        this.tableView = tableView
    }



    findParentDatasetId(e) {
        const targetParent = e.target.closest('div[data-id]');
        console.log('targetParent', targetParent)
        console.log('targetParent.id', targetParent.dataset.id)

        return targetParent.dataset.id;
    }

    deleteItemFromIntegrationList(id) {
        this.integrationsList = this.integrationsList.filter(item => item.id !== id)
    }


    async fetchPureIntegrationsList() {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().get("/integrations");
            this.setIntegrationsList(res.data);
            return res.data;
        } catch (error) {
            console.error("ошибка fetchIntegrationsList", error.message)
        }
    }

    async fetchIntegrationsList() {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().get("/integrations");
            await MenuStore.fetchShowcase();
            res.data = res.data.map(item => {
                item.items = [];

                const getMenuListInIntegration = () => {
                    console.log("getMenuListInIntegration")
                    for (let menu of MenuStore.showcase) {
                        if (menu.integrationId === item.id) {
                            item.items.push({ name: menu.name, id: menu.id })
                        }
                    }
                }
                getMenuListInIntegration();
                return item;
            })


            this.setIntegrationsList(res.data);

            console.debug("fetchIntegrationsList", res.data)
            return res.data;
        } catch (error) {
            console.error("ошибка fetchIntegrationsList", error.message)
        }
    }

    async createIntegration(item) {
        item = JSON.parse(JSON.stringify(item))
        item.type = +item.type
        let {
            type,
            name,
            login,
            password,
            organization,
            terminalGroup,
            paymentSystem,
            hostPort } = item;


        // if (!paymentSystemId) paymentSystemId = null;
        console.log("createIntegration item after", paymentSystem)

        let data = {};

        console.log("createIntegration item before", item)

        switch (item.type) {
            case 0: data = { type, name, paymentSystem }; break;
            case 1: data = { type, name, login, password, organization, paymentSystem }; break;
            case 2: data = { type, name, login, organization, terminalGroup, paymentSystem }; break;
            case 3: data = { type, name, login, password, hostPort }; break;
            default: console.warn("createIntegration switch failed"); break;
        }

        try {
            console.log("createIntegration data", data);
            const res = await ConnectionManager.GetInstance().GetClient().post("/integrations", data);
            this.fetchIntegrationsList();

            MsgStore.showMsg({ value: "Интеграция успешно создана", status: "ok" });
            return res;
        } catch (error) {
            console.error("ошибка createIntegration", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });
            // MsgStore.showMsg({ value: "Ошибка создания интеграции", status: "error" });
        }
    }

    async updateIntegration(item) {
        console.log("updateIntegration", item)

        try {
            const res = await ConnectionManager.GetInstance().GetClient().put("/integrations", item);
            this.fetchIntegrationsList();

            MsgStore.showMsg({ value: "Интеграция успешно обновлена", status: "ok" });
            return res;
        } catch (error) {
            console.error("ошибка функции updateIntegration", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });
        }

    }

    async deleteIntegration(id) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().delete(`/integrations/${id}`);
            this.deleteItemFromIntegrationList(id);
            console.log("deleteIntegration");
            MsgStore.showMsg({ value: "Интеграция успешно удалена", status: "ok" });

            return res.data;
        } catch (error) {
            console.error("ошибка updateIntegration", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка удаления интеграции", status: "error" });

        }
    }

    async fetchOrganisation(item) {
        try {
            let { login, password, type } = item;
            type = +type

            let data = {}

            switch (type) {
                case 1: data = { login, password, type }; break;
                case 2: data = { login, type }; break;
                default: console.warn("AAAAAA!! fetchOrganisation"); break;
            }

            const res = await ConnectionManager.GetInstance().GetClient().post(`/integrations/organizations`, data);
            if (res.data.length === 0) {
                MsgStore.showMsg({ value: "Получен пустой список ресторанов", status: "warning" });
            } else {
                MsgStore.showMsg({ value: "Рестораны получены", status: "ok" });
            }
            return res.data;
        } catch (error) {
            console.log("ошибка функции fetchOrganisation", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка получения организаций", status: "error" });
        }
    }

    async fetchTerminalGroups(item) {

        console.log("fetchTerminalGroups", item)

        try {
            let { login, type, organization } = item;
            type = +type

            if (type !== 2 || organization === '') return []
            let data = {}

            data = { login, type, organization }
            // data.organization = item.terminalGroup;

            const res = await ConnectionManager.GetInstance().GetClient().post(`/integrations/terminalgroups`, data);

            MsgStore.showMsg({ value: "Группы терминалов получены", status: "ok" });
            return res.data;
        } catch (error) {
            console.log("ошибка функции fetchOrganisation", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка получения групп терминалов", status: "error" });
        }
    }

    async fetchPaymentSystem(item) {
        try {

            let data = {}

            data = {
                login: item.login.trim(),
                type: +item.type,
                organization: item.organization,
            }
            if (data.type === 1) data.password = item.password.trim();

            const res = await ConnectionManager.GetInstance().GetClient().post(`/integrations/PaymentSystems`, data);

            MsgStore.showMsg({ value: "Платежные системы получены", status: "ok" });
            return res.data;
        } catch (error) {
            console.log("ошибка функции fetchOrganisation", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка получения платежных систем", status: "error" });
        }
    }

    async getOrderTypes(integrationId) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().post(`/integrations/OrderTypes`, { id: integrationId });
            // MsgStore.showMsg({ value: "Платежные системы получены", status: "ok" });
            return res.data;

        } catch (error) {
            console.log("ошибка функции getOrderTypes", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "getOrderTypes error", status: "error" });
        }
    }

    async getMenuFromOrganisation(orgId) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().get(`/integrations/${this.activeIntegration.id}/catalog`, { params: { orgId } });
            MsgStore.showMsg({ value: "Каталог загружен", status: "ok" });

            return res.data;
        } catch (error) {
            console.log("ошибка функции getMenuFromOrganisation", error.message);
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });

            // MsgStore.showMsg({ value: "Ошибка загрузки каталога", status: "error" });

        }
    }

    parentIdRecursionChecker(id, ItemsArr, config = { reverse: false }) {

        let data = JSON.parse(JSON.stringify(ItemsArr))

        const recursionChecking = (elements, id) => {
            for (let elem of elements) {
                if (elem.del) continue
                if (elem.id === id) { elem.del = true; continue }
                if (elem.parent === id) {
                    elem.del = true;
                    recursionChecking(elements, elem.id);
                }
            }
        }
        recursionChecking(data, id);

        if (config.reverse) return data.filter(item => item.del === true).map(item => { if (item.del) delete item.del; return item })
        return data.filter(item => item.del !== true)
    }
}

export default new AdminStore();