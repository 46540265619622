import { makeAutoObservable, runInAction, toJS } from "mobx";

import { ConnectionManager } from '../http/axios';

import { v4 as uuidv4 } from 'uuid';
import AdminStore from "./AdminStore";
import MsgStore from "./MsgStore";
import CatalogStore from './CatalogStore';
// const editableMenuDefault = [{
//     name: "",
//     id: 0,
//     items: [0]
// }];

const editableMenuDefault = [{
    banner: "",
    description: "",
    id: "",
    items: [],
    name: "",
    type: 0,
    hoursOfWork: [],
    integrationId: "",
    integrationType: 0,
    tables: [],
    wiFi: null,
    published: true,
    PaymentSystemTypes: [0],
    addons: []
}]

class MenuStore {
    showcase = [{
        name: '',
        id: "0"
    }];

    editableMenu = editableMenuDefault;

    get editableMenuIntegrationType() {
        return this.editableMenu?.[0]?.integrationType ? this.editableMenu?.[0]?.integrationType : 0
    }

    get menuNamesDictionary() {
        let dictionary = {}
        for (let menu of this.showcase) {
            console.log(menu)
            dictionary = { ...dictionary, [menu.id]: menu.name }
        }
        return dictionary
    }

    get editableMenuAddons() {
        return this.editableMenu[0].addons
    }

    setEditableMenuAdditionalItemAmount(addonId, itemId, amount) {
        console.log(addonId, itemId, amount)
        // console.log( toJS())
        // console.log( toJS())

        for (let addon of this.editableMenu[0].addons) {
            if (addon.id === addonId) {
                for (let item of addon.additionalItems) {
                    if (item.id === itemId) {
                        item.amount = amount;
                        console.log(item.amount)
                        return;
                    }
                }
            }
        }
    }


    activeDnDItem = {};
    activeDnDStartField = '0';

    // getEditableMenuItems() {
    //     return this.editableMenu[0].items
    // }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setActiveDnDItem(activeDnDItem) {
        this.activeDnDItem = activeDnDItem;
    }

    setActiveDnDItemParentId(parentId) {
        this.activeDnDItem.parent = parentId
    }

    setActiveDnDStartField(activeDnDStartField) {
        this.activeDnDStartField = activeDnDStartField;
    }

    setMenuList(showcase) {
        this.showcase = showcase;
    }

    setEditableMenu(editableMenu) {
        console.log("setEditableMenu")
        this.editableMenu = editableMenu;
    }

    setEditableMenuName(name) {
        if (this.editableMenu[0]) this.editableMenu[0].name = name
    }

    setEditableMenuAddons(addons) {
        this.editableMenu[0].addons = addons
    }

    createNewEditableMenu() {
        let newMenu = JSON.parse(JSON.stringify(editableMenuDefault))
        // let newMenu = [{
        //     name: "",
        //     description: "",
        //     published: true,
        //     banner: "",
        //     items: [],
        //     id: ''
        // }];
        this.editableMenu = newMenu;
    }

    addItemToEditableMenu(activeItem, data) {
        let id = uuidv4();

        activeItem = { ...activeItem, ...data, id };
        this.editableMenu[0].items.push(activeItem);
        return
    }


    addItemToEditableMenuWithMods(activeItem, data, config = { isReplaceId: true, targetToCopy: false }) {
        if (!config.targetToCopy) config.targetToCopy = this.editableMenu?.[0]?.items

        // this.editableMenu?.[0]
        // !!! оптимизировать?
        // сделать проверку на то, что если такой айтем уже есть, не переписывать его, а пропустить копирование 
        activeItem = JSON.parse(JSON.stringify(activeItem))
        let id = uuidv4();
        config.isReplaceId ? activeItem = { ...activeItem, ...data, id } : activeItem = { ...activeItem, ...data }

        // проверка на то, что такого айтема, который матчится с modId в каталоге нет
        if (activeItem.modifiers?.length > 0) {
            for (let mod of activeItem.modifiers) {
                const modItemFromCatalog_1 = CatalogStore.getCatalogItemById(mod.id);
                if (!modItemFromCatalog_1?.id) {
                    MsgStore.showMsg({
                        value: "модификатор не найден внутри каталога, копирование отменено",
                        status: "warning",
                    })
                    return
                }
            }
        }

        //========================

        // копируем айтемы модификаторов из каталога в editableMenu
        if (activeItem.modifiers?.length > 0) {
            activeItem.modifiers.forEach(mod => {
                let id = uuidv4();
                const modItemFromCatalog_1 = CatalogStore.getCatalogItemById(mod.id);

                // проверка, чтобы не перетирать id в уже существующих айтемах
                if (config.targetToCopy.filter(({ type, item }) => type === 3 && item === mod.id)?.[0]?.item === mod.id) return

                // копирование айтемов
                config.targetToCopy.push({ ...modItemFromCatalog_1, id, item: modItemFromCatalog_1.id, parent: null,   /* parent: activeItem.parent */  /* !!! parent: null */ });
            })

            // перебираем модификаторы внутри копируемого продукта и добавляем в них поле item
            activeItem.modifiers.forEach(mod => {
                mod.item = mod.id;
                const modItemFromEditableMenu = config.targetToCopy.filter(({ type, item }) => item === mod.item)?.[0];
                if (modItemFromEditableMenu?.item === mod.id) mod.id = modItemFromEditableMenu.id;
            })
        }

        //========================

        /* корерктно скопировать айтемы + групповые айтемы */
        /* пройтись по данным в active product и заменить поля айтем и айди*/

        // перебираем групповые модификаторы продукта
        if (activeItem.groupModifiers?.length > 0) {
            // перебор полей внутри копируемого продукта
            // копируем айтемы групповый модификаторов из каталога в editableMenu
            activeItem.groupModifiers.forEach(groupMod => {
                let newGroupModId = uuidv4();
                const modItemFromCatalog_2 = CatalogStore.getCatalogItemById(groupMod.id);

                // проверяем, чтобы не перетереть уже существующие айтемы
                if (this.matchCatalogItemInShowcase(groupMod.id, { targetToMatch: config.targetToCopy })?.item === groupMod.id) return
                config.targetToCopy.push({ ...modItemFromCatalog_2, id: newGroupModId, item: modItemFromCatalog_2.id, parent: null,   /* parent: activeItem.parent */  /* !!! parent: null */ });

                // копируем айтемы childModifiers of GroupModifiers из каталога в editableMenu (showcase)
                groupMod.childModifiers.forEach(mod => {
                    let newModId = uuidv4();
                    const modItemFromCatalog_3 = CatalogStore.getCatalogItemById(mod.id);

                    // если такой айтем уже есть в showcase, отмена (поиск по полю "item")
                    if (this.matchCatalogItemInShowcase(groupMod.id, { targetToMatch: config.targetToCopy })?.item === mod.id) return

                    // копируем айтем из каталога
                    config.targetToCopy.push({ ...modItemFromCatalog_3, id: newModId, item: modItemFromCatalog_3.id, parent: newGroupModId,   /* parent: activeItem.parent */  /* !!! parent: null */ });
                })

                // перебираем чайлд модификаторы внутри копируемого продукта и добавляем в них поле item
                groupMod.childModifiers.forEach(mod => {
                    mod.item = mod.id;
                    const modItemFromEditableMenu = config.targetToCopy.filter(({ type, item }) => item === mod.item)?.[0];
                    if (modItemFromEditableMenu?.item === mod.id) mod.id = modItemFromEditableMenu.id;
                })

            })

            // перебираем модификаторы групп внутри копируемого продукта и добавляем в них поле item
            activeItem.groupModifiers.forEach(mod => {
                mod.item = mod.id;
                const modItemFromEditableMenu = config.targetToCopy.filter(({ type, item }) => item === mod.item)?.[0];
                if (modItemFromEditableMenu?.item === mod.id) mod.id = modItemFromEditableMenu.id;

                // перебираем чайлд модификаторы внутри групп внутри копируемого продукта и добавляем в них поле item
                mod.childModifiers.forEach(mod => {
                    mod.item = mod.id;
                    const modItemFromEditableMenu = config.targetToCopy.filter(({ type, item }) => item === mod.item)?.[0];
                    if (modItemFromEditableMenu?.item === mod.id) mod.id = modItemFromEditableMenu.id;
                })
            })

        }

        config.targetToCopy.push(activeItem);
        return
    }


    pureAddItemToEditableMenu(activeItem, data) {
        activeItem = { ...activeItem, ...data };
        this.editableMenu[0].items.push(activeItem);
        return;
    }

    deleteItemFromEditableMenu(itemId) {
        console.log("deleteItemFromEditableMenu itemId", itemId)
        this.editableMenu[0].items = this.editableMenu?.[0].items.filter(elem => elem.id !== itemId)
    }

    matchCatalogItemInShowcase(catalogId, config = { targetToMatch: this.editableMenu[0].items }) {
        return config.targetToMatch.filter(showcaseItem => showcaseItem.item === catalogId)?.[0]
    }

    // создаем объект со списком и количеством айтемов
    createMenuModIdList = () => {
        let modList = {}
        const addItemToList = (item) => { modList[item.id] ? modList[item.id] = ++modList[item.id] : modList[item.id] = 1; }

        this.editableMenu?.[0]?.items.forEach(item => {
            item?.groupModifiers?.forEach(groupModifier => {
                addItemToList(groupModifier)
                groupModifier.childModifiers.forEach(childModifier => {
                    addItemToList(childModifier)
                })
            })
        })
        return modList;
    }

    deepDeleteItemFromEditableMenu(itemId) {
        // !!! сделать удаление модификаторов при удалении группы
        const deleteItem = this.editableMenu[0].items.filter(item => item.id === itemId)[0];

        const deleteMods = (arrOfMods) => {
            arrOfMods.forEach(mod => {
                let modCounter = 0;
                for (let item of this.editableMenu[0].items) {
                    if (!item?.modifiers) continue;
                    for (let modifier of item.modifiers) if (modifier.id === mod.id) modCounter++;
                }
                if (modCounter === 1) this.deleteItemFromEditableMenu(mod.id);
            })
        }



        switch (deleteItem.type) {
            case 1: //продукт
                deleteMods(deleteItem.modifiers)
                const menuModIdList = this.createMenuModIdList();

                // удаление айтемов, которые матчатся с gropModifiers
                deleteItem?.groupModifiers?.forEach(groupModifier => {
                    if (menuModIdList[groupModifier.id] === 1) this.deleteItemFromEditableMenu(groupModifier.id)
                    groupModifier?.childModifiers?.forEach(childModifier => {
                        if (menuModIdList[childModifier.id] === 1) this.deleteItemFromEditableMenu(childModifier.id)
                    })
                })

                this.deleteItemFromEditableMenu(itemId);

                break;
            case 2: //группа
                // удаление  и чайлды вавыливаются из группы
                this.editableMenu[0].items.forEach(item => {
                    if (item.parent === itemId) item.parent = deleteItem.parent
                })
                this.deleteItemFromEditableMenu(itemId)


                // старый вариант удаение с чайлдами 
                // items = AdminStore.parentIdRecursionChecker(itemId, this.editableMenu?.[0].items);
                // this.editableMenu[0].items = items;
                break;
            case 3: //модификатор 

                // удаление модификатора, если он НЕ присуствует ни в одном из продуктов 
                for (let menuItem of this.editableMenu?.[0].items) {
                    if ((!menuItem.modifiers || menuItem.modifiers?.length === 0) && (!menuItem.groupModifiers || menuItem.groupModifiers?.length === 0)) continue;

                    // проверка на модификаторы
                    for (let modifier of menuItem.modifiers) {
                        if (modifier.id === deleteItem.id) {
                            MsgStore.showMsg({ value: "Удаление отменено, модификатор присутствует в товаре", status: "warning" }, 5000);
                            return;
                        }
                    }
                    // проверка на групповые модификаторы
                    for (let groupModifier of menuItem.groupModifiers) {
                        if (groupModifier.childModifiers?.length === 0) continue;

                        for (let modifier of groupModifier.childModifiers) {
                            if (modifier.id === deleteItem.id) {
                                MsgStore.showMsg({ value: "Удаление отменено, модификатор присутствует в товаре", status: "warning" }, 5000);
                                return;
                            }
                        }
                    }


                }
                this.deleteItemFromEditableMenu(itemId)


                break;
            case 4: //групповой модификатор

                for (let menuItem of this.editableMenu?.[0].items) {
                    if (!menuItem.groupModifiers || menuItem.groupModifiers?.length === 0) continue;

                    // проверка на групповые модификаторы
                    for (let groupModifier of menuItem.groupModifiers) {
                        if (groupModifier.childModifiers?.length === 0) continue;
                        if (groupModifier.id === deleteItem.id) {
                            MsgStore.showMsg({ value: "Удаление отменено, групповой модификатор присутствует в товаре", status: "warning" }, 5000);
                            return;
                        }

                    }
                }

                // удаляем, чайлды вываливаются из группы
                this.editableMenu[0].items.forEach(item => {
                    if (item.parent === itemId) item.parent = deleteItem.parent
                })
                this.deleteItemFromEditableMenu(itemId)
                break;

            default:
                break;
        }

        // //если мы удаляем модификатор, то удаляем его из всех продуктов
        // if (deleteItem.type === 3) {
        //     for (let menuItem of this.editableMenu[0].items) {
        //         if (!menuItem.modifiers) continue;
        //         for (let modifier of menuItem.modifiers) {
        //             if (modifier.id === deleteItem.id) menuItem.modifiers = menuItem.modifiers.filter(mod => mod.id !== deleteItem.id)
        //         }
        //     }
        // }
    }


    getItemFromEditableMenuById(id) {
        if (id === '') return false;
        return JSON.parse(JSON.stringify(this.editableMenu?.[0].items.filter(elem => elem.id === id)[0]));
    }


    async fetchShowcase() {

        AdminStore.setShowcaseIsLoading(true);
        try {
            const res = await ConnectionManager.GetInstance().GetClient().get("/showcase/");
            this.setMenuList(res.data);
            AdminStore.setShowcaseIsLoading(false);
            console.debug('fetchShowcase, setMenuList, ', res.data);

            return res.data;
        } catch (error) {
            AdminStore.setShowcaseIsLoading(false);
            console.error('ошибка функции fetchShowcase', error.message)
        }
    }

    async updateShowcaseMenu(menu = this.editableMenu?.[0], config = { replaceTables: false }) { //refactor
        // здесь в одну функцию объединенены апдейт и создание нового меню, 
        // т.к у нас одна кнопка на все это

        menu.type = +menu.type

        if (menu.orderTypeId === '') menu.orderTypeId = null

        if (!menu?.id) {//создание нового меню
            try {
                const body = JSON.parse(JSON.stringify(menu))
                let menuItems = body.items;

                for (let itemKey in menuItems) {
                    for (let key in menuItems[itemKey]) if (menuItems[itemKey][key] === '') menuItems[itemKey][key] = null;
                    if (!menuItems[itemKey].ingredients) menuItems[itemKey].ingredients = [];
                    if (!menuItems[itemKey].measure) menuItems[itemKey].measure = '';
                    if (!menuItems[itemKey].currency) menuItems[itemKey].currency = '';
                    if (!menuItems[itemKey].description) menuItems[itemKey].description = '';
                }

                body.items = body.items?.filter(elem => elem.id !== null)

                console.log("updateShowcaseMenu body", body)
                const res = await ConnectionManager.GetInstance().GetClient().post('/showcase/', body)


                this.fetchShowcase();
                this.setEditableMenu(editableMenuDefault);

                MsgStore.showMsg({ value: "Меню успешно создано", status: "ok" });

                return res;

            } catch (error) {
                console.error("ошибка функции updateShowcaseMenu", error.message)
                MsgStore.showMsg({ value: error.response.data.title, status: "error" });

                // MsgStore.showMsg({ value: "Ошибка создания меню", status: "error" });
            }
        } else {
            try {
                let body = menu;

                let menuItems = body.items;
                for (let itemKey in menuItems) {
                    for (let key in menuItems[itemKey]) if (menuItems[itemKey][key] === '') menuItems[itemKey][key] = null;
                    if (!menuItems[itemKey].ingredients) menuItems[itemKey].ingredients = [];
                    if (!menuItems[itemKey].measure) menuItems[itemKey].measure = '';
                    if (!menuItems[itemKey].currency) menuItems[itemKey].currency = '';
                    if (!menuItems[itemKey].description) menuItems[itemKey].description = '';
                }

                if (config.replaceTables) body.tables = body.tables.filter(table => table.isChecked)

                const res = await ConnectionManager.GetInstance().GetClient().put('/showcase/', body)
                console.log("updateShowcaseMenu", body)
                MsgStore.showMsg({ value: "Меню успешно обновлено", status: "ok" });

                this.fetchShowcase()
                    .then(() => this.setEditableMenu(this.showcase.filter(menu => menu.id === this.editableMenu?.[0].id)))

                return res;

            } catch (error) {
                console.error("ошибка функции updateShowcaseMenu", error.message);
                MsgStore.showMsg({ value: error.response.data.title, status: "error" });

                // MsgStore.showMsg({ value: "Ошибка обновления Меню", status: "error" });
            }
        }
    }

    async deleteMenu(id) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().delete(`/showcase/${id}`);

            runInAction(() => {
                this.showcase = this.showcase.filter(menu => menu.id !== id);
                this.editableMenu = editableMenuDefault;
                MsgStore.showMsg({ value: "Меню успешно удалено", status: "ok" });
            })

            return res.data;
        } catch (error) {
            console.error("ошибка deleteMenu", error.message)
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });
        }
    }

    updateItemInEditableMenu(item) {
        this.editableMenu[0].items = this.editableMenu?.[0].items.map(elem => {
            if (elem.id === item.id) {
                for (let key in item) if (item[key] === '') item[key] = null;
                return item;
            } else {
                return elem;
            }
        })
    }

    moveItemInEditableMenu = (activeItem, data) => {

        for (let item of this.editableMenu?.[0]?.items) {
            if (item.id === activeItem.id && activeItem.type === 2) {
                // item = {...item, ...data}
                item.parent = data.parent
                item.index = data.index
                console.log("let items of this.editableMenu?", item, data)
            }
        }

        return
    }

    changeSortIndex(targetParentGroupId) {
        let sortCounter = 1
        this.editableMenu?.[0].items?.sort((a, b) => a.index > b.index).forEach(item => {
            if (item.parent !== targetParentGroupId) return
            item.index = ++sortCounter * 2;
        })
    }

    async getTables(IntegrationId) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().post(`/showcase/Tables`, { IntegrationId });
            return res.data;
        } catch (error) {
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });
        }
    }

    refreshDataFromIntegration() {
        if (this.editableMenu?.[0]?.items.length === 0) return

        let rootItems = AdminStore.parentIdRecursionChecker(null, this.editableMenu?.[0].items, { reverse: true });

        console.log("rootItems", rootItems)
        let newMenuItems = []
        rootItems
            .filter(item => item.type === 1 || item.type === 2)
            .forEach(menuItem => {

                const catalogItem = CatalogStore.getCatalogItemById(menuItem.item)
                if (!catalogItem) return

                this.addItemToEditableMenuWithMods(catalogItem,
                    {
                        id: menuItem.id,
                        item: menuItem.item,
                        parent: menuItem.parent,
                        index: menuItem.index
                    },
                    { isReplaceId: false, targetToCopy: newMenuItems })

            })

        this.editableMenu[0].items = newMenuItems;

    }

    //addons

    createAddon = () => {
        let newAddon = [
            {
                id: uuidv4(),
                condition: {
                    id: uuidv4(),
                    combinator: "and",
                    rules: []
                },
                additionalItems: []
            }
        ]

        console.log("this.editableMenu[0].addons", this.editableMenu[0].addons)

        this.editableMenu[0].addons ? this.editableMenu[0].addons.push(...newAddon) : this.editableMenu[0].addons = newAddon
    }

    updateAddon(q) {
        let item = JSON.parse(JSON.stringify(q));
        const addons = this.editableMenu[0]?.addons;
        const addon = addons.find(addon => addon?.condition?.id === item.id);
        if (addon) addon.condition = item;
    }

    deleteAddon = (id) => {
        this.editableMenu[0].addons = this.editableMenu[0].addons.filter(item => item.id !== id);
    }

    findAddonInEditableMenu(id) {
        return this.editableMenu[0].addons.find(item => item.id === id);
    }

    addProductToAddon(catalogItem, addonId) {
        console.log("addProductToAddon", catalogItem, addonId)

        const addon = this.findAddonInEditableMenu(addonId)
        const item = JSON.parse(JSON.stringify(catalogItem));
        item.item = item.id;
        item.id = uuidv4();
        addon.additionalItems.push(item);
        // console.log("addProductToAddon", arguments)
    }
}

export default new MenuStore();