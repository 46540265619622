import React from 'react';
import { observer } from "mobx-react-lite";

import classnames from 'classnames';
import styles from './Message.module.scss';

import MsgStore from '../../store/MsgStore';


const Message = () => {

    let msgStyle
    switch (MsgStore.msg.status) {
        case "ok":
            msgStyle = styles.msg_ok
            break;
        case "error":
            msgStyle = styles.msg_error
            break;
        case "warning":
            msgStyle = styles.msg_warning
            break;
        default:
            break;
    }

    let msg_hider
    if (!MsgStore.isVisible) msg_hider = styles.hide

    return (
        <div className={msg_hider}>
            <div className={classnames(msgStyle, msg_hider, styles.msg_wrapper, styles.msg_visibility)}>
                {MsgStore.msg.value}
            </div>
        </div>
    );
};

export default observer(Message);