import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import PopupStore from '../../store/PopupStore';
import AdminStore from '../../store/AdminStore';
import KassaStore from '../../store/KassaStore';

// import Layout from '../../Layout';
import IntegrationList from '../../components/IntegrationList';

import styles from './IntegrationsPage.module.scss';
import Page from '../../Layout/Page';

const IntegrationsPage = () => {

    const [IsIntegrLoading, setIsIntegrLoading] = useState(false);

    useEffect(() => {
        setIsIntegrLoading(true);
        AdminStore.fetchIntegrationsList().then(() => setIsIntegrLoading(false));
    }, [])

    const onCreateIntegration = (item) => {
        console.debug("onCreateIntegration item", item);

        AdminStore.resetActiveIntegration();
        PopupStore.setActiveForm("newIntegration");
    }

    const onUpdateIntegrationHandler = (id) => {
        console.debug("onUpdateHandler");

        AdminStore.setActiveIntegration(id);
        PopupStore.setActiveForm("integration");
    }

    const onDeleteIntegrationHandler = (id) => {
        console.debug("onDeleteHandler", id);

        AdminStore.deleteIntegration(id);
    }

    const buttons = [{ text: "Добавить ресторан", onClick: onCreateIntegration, icon: "fas fa-plus" }]

    useEffect(() => {
        KassaStore.fetchKassaList()
    }, [])

    return (
        <Page buttons={buttons}>
            <div className={styles.list_wrapper}>
                <div className={styles.content_title}>
                    Рестораны
                </div>

                <IntegrationList
                    isLoading={IsIntegrLoading}
                    items={AdminStore.integrationsList}
                    onUpdateHandler={onUpdateIntegrationHandler}
                    onDeleteHandler={onDeleteIntegrationHandler}
                />
            </div>
        </Page>
    );
};

export default observer(IntegrationsPage);




