import React from 'react';
import { observer } from 'mobx-react-lite';

import classnames from 'classnames';

import CatalogStore from '../../store/CatalogStore';
import PopupStore from '../../store/PopupStore';

import ItemList from '../ItemList';

import styles from './Catalog.module.scss';
import MenuStore from '../../store/MenuStore';
import AdminStore from '../../store/AdminStore';
import { useEffect } from 'react';

const Catalog = ({
    showBtn,
}) => {

    const onUpdateHandlerGroup = (id) => {
        console.debug("onUpdateHandlerGroup", id);

        PopupStore.setActiveForm("group");
        const activeItem = CatalogStore.getCatalogItemById(id);
        CatalogStore.setCatalogActiveItem(activeItem);
    }

    const onUpdateHandlerProduct = (id) => {
        console.debug("onUpdateHandlerProduct", id);

        PopupStore.setActiveForm("product");
        const activeItem = CatalogStore.getCatalogItemById(id);
        CatalogStore.setCatalogActiveItem(JSON.parse(JSON.stringify(activeItem)));
    }

    const onUpdateHandlerModificator = (id) => {
        console.debug("onUpdateHandlerModificator");

        PopupStore.setActiveForm("modificator");
        const activeItem = CatalogStore.getCatalogItemById(id);
        CatalogStore.setCatalogActiveItem(JSON.parse(JSON.stringify(activeItem)));
    }

    const onDeleteHandler = (id) => {
        console.debug("onDeleteHandler", id);

        CatalogStore.deleteCatalogItem(id);
    }

    useEffect(() => {
        AdminStore.fetchPureIntegrationsList();
    }, [])

    let integrationName
    if (AdminStore?.integrationsList?.length > 0) {
        integrationName = AdminStore?.integrationsList?.filter(integration => integration.id === MenuStore?.editableMenu?.[0]?.integrationId)?.[0]?.name
    }

    return (
        <>
            <div className={styles.menu_title_wrapper}>
                {MenuStore?.editableMenu?.[0]?.integrationType !== 0 ?

                    <div className={styles.menu_title}>
                        Каталог ресторана - {integrationName}
                    </div>
                    :
                    <div className={styles.menu_title}>
                        Локальный каталог
                    </div>
                }
            </div>
            <div 
            className={classnames(styles.catalog, 'catalog', {[styles.table]: AdminStore.tableView})} 
            data-fieldtype='catalog'>
                <ItemList
                    showBtn={showBtn}
                    items={CatalogStore.catalog}
                    onUpdateHandlerGroup={onUpdateHandlerGroup}
                    onUpdateHandlerProduct={onUpdateHandlerProduct}
                    onUpdateHandlerModificator={onUpdateHandlerModificator}
                    onDeleteHandler={onDeleteHandler}
                />
            </div>
        </>
    );
};

export default observer(Catalog);