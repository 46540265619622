export const taxationSystemLabels = [
    'Общая система налогообложения',
    'Упрощенная система налогообложения (Доход)',
    'Упрощенная система налогообложения (Доход минус Расход)',
    'Единый налог на вмененный доход',
    'Единый сельскохозяйственный налог',
    'Патентная система налогообложения',
]

export type OrderDeliveryAddress = {
    city: string;
    street: string;
    house: string;
    index?: string;
    building?: string;
    flat?: string;
    entrance?: string;
    floor?: string;
    doorphone?: string;
    comment?: string;
}

export type KktModel = {
    name: string;
    id: string;
}

type ShowcaseProductSize = {
    id: string;
    name: string;
    price: number;
}

type OrderItemModifier = {
    id: string;
    amount: number;
    price: number;
    minAmount: number;
    maxAmount: number;
    name: string;
    image?: string;
    item: string;
}

type OrderGroupModifier = {
    id: string;
    minAmount: number;
    maxAmount: number;
    childModifiers: OrderItemModifier[];
    name: string;
    item: string;
}


export type OrderItem = {
    id: string;
    name: string;
    price: number;
    amount: number;
    item: string;
    article?: string;
    size?: ShowcaseProductSize;
    modifiers: OrderItemModifier[];
    groupModifiers: OrderGroupModifier[];
}

type InvoiceDetails = {
    dateTime?: string;
    id: string;
    sum: number;
    error?: string;

    status: 0 | 1 | 2 | 3 | 4 | 5;
    /* 
    enum InvoiceState
    {
        Created,
        Checked,
        Paid,
        Confirmed,
        Cancelled,
        Fault
    }
    */
}

//CreateOrderCommand
export type order = {
    id: string;

    number?: string;
    dateTime?: string;
    currentMenu: string;

    
    restaurant: string;
    guestId?: string;
    items: OrderItem[];
    invoices: InvoiceDetails[];
    
    phone?: string;
    tableId?: string;
    tableNumber?: number;
    email?: string;
    stockId?: string;
    contragentInn?: string;
    address?: OrderDeliveryAddress;
    orderTypeId: string;
    paymentType: KktModel;
    contragentId?: string;
    
    showcaseType: 0 | 1 | 2 | 3 | 4;
    /* 
    enum ShowcaseType
    {
        Vitrin,
        TableSystem,
        Delivery,
        OutgoingInvoices,
        IncomingInvoice
    }
    */
   
   amount: number;
   paymentId: string;
   
   tenantId?: string;
   status?: "Created" | "Paid" | "Pushed" | "Error" | "Closed" | "Deleted";
}

export type kassa = {
    name: string;
    id?: string;

    taxationSystem?: number; //(0-5)
    /* 
        'Общая система налогообложения',                            0
        'Упрощенная система налогообложения (Доход)',               1
        'Упрощенная система налогообложения (Доход минус Расход)',  2
        'Единый налог на вмененный доход',                          3
        'Единый сельскохозяйственный налог',                        4
        'Патентная система налогообложения',                        5
    */

    inn?: string;
    hashPassword?: string;
    publicId?: string;
    isFiscal: boolean | null;
}


export type IntegrationType = 0 | 1 | 2 | 3;
//["local", "IIKO BIZ", "IIKO Transport", "RMS"]

export interface IIntegration {
    name: string;
    type: IntegrationType;

    login?: string;
    password?: string;

    id?: string;
    hostPort?: string;
    paymentSystem?: IPaymentSystem;

    organization?: string;
    terminalGroup?: string;

    paymentSystemId?: string;

    //with data
    items?: { id: string, name: string }[]; //меню на этой интеграции

    // //local
    // name: string;
    // type: number;

    // //BIZ
    // "type": 1,
    // "name": "IIKO Biz",
    // "login": "flagman-dev-api",
    // "password": "uSK-7zR-Kc5-wxy",
    // "organization": "5f850000-90a3-0025-a081-08d915fb7a16",
    // "paymentSystemId": "09322f46-578a-d210-add7-eec222a08871"

    //Transport
    // "type": 2,
    // "name": "IIKO Transport",
    // "login": "5e0475ec-00b",
    // "organization": "8a8c64dd-5364-4541-b1ad-f3ba08e56543",
    // "terminalGroup": "36b1e872-d78c-a797-0169-94fe0f6a00cc",
    // "paymentSystemId": "09322f46-578a-d210-add7-eec222a08871"

    //RMS
    // "type": 3,
    // "name": "IIKO Server",
    // "login": "admin",
    // "Password":"admin",
    // "HostPort":"http://ffood.54fz.club/resto"
}

export interface IPaymentSystem {
    id: string;
    name: string;
    isFiscal: boolean | null;
}