import React from 'react';
import { observer } from 'mobx-react-lite';

import PopupStore from '../../../store/PopupStore';

import moment from 'moment'
import 'moment/locale/ru'

import FormWrapper from '../FormWrapper';

import styles from './OrderDetails.module.scss';
import { toJS } from 'mobx';

const OrderDetails = ({
    onSubmit = () => console.log("GroupForm onSubmit"),
    mode = "catalog"
}) => {
    const formData = PopupStore.activeFormData.formData

    console.log("OrderDetails PopupStore.activeFormData", toJS(PopupStore.activeFormData))

    const {
        currentMenuName,
        number,
        paymentType,

        address,

        contragentId,
        contragentInn,

        dateTime,
        status,
        sum,
        amount,
        items,

        phone,
        email,

        tableNumber,
        restaurantName,

        showcaseType,
        comment

    } = formData

    return (
        <FormWrapper title={"Детали заказа"}>
            <div>
                {/* address */}
                <div className={styles.info_block}>
                    Меню: {currentMenuName}
                    <br />
                    Ресторан: {restaurantName}
                    <br />
                    Номер заказа: {number}
                    <br />
                    Тип оплаты: {paymentType}
                </div>

                <div className={styles.info_block}>
                    Время: {moment(dateTime).locale("ru").format('LTS, L')}
                    <br />
                    Статус заказа: {status}
                    <br />
                    Сумма: {amount}
                </div>

                {showcaseType === 1 &&
                    <div className={styles.info_block}>
                        Стол номер {tableNumber}
                    </div>
                }

                {showcaseType === 2 &&
                    <div className={styles.info_block}>
                        Телефон: {phone}
                        <br />
                        Email: {email}
                    </div>
                }

                {showcaseType === 2 &&
                    <>
                        <div className={styles.info_block}>
                            {address?.city},
                            &nbsp;
                            {address?.street},
                            &nbsp;
                            {address?.house}
                            <br />
                            корпус {address?.building}
                            &nbsp;
                            Квартира {address?.flat}
                            &nbsp;
                            <br />
                            домофон {address?.doorphone}
                            &nbsp;
                            Подъезд {address?.entrance}
                            &nbsp;
                            Этаж {address?.floor}
                            <br />
                        </div>
                        <div className={styles?.info_block}>
                            Комментарий к адресу
                            <br />
                            {address?.comment}
                        </div>

                        <div className={styles?.info_block}>
                            Комментарий к заказу
                            <br />
                            {comment}
                        </div>
                    </>
                }

                {[3, 4].includes(showcaseType) &&
                    <div className={styles.info_block}>
                        Контрагент ID {contragentId}
                        <br />
                        Контрагент ИНН {contragentInn}
                    </div>
                }

                {items?.length > 0 &&
                    <div className={styles.info_block}>
                        {items.map(item => (
                            <div className={styles.orderList_item} key={item.id}>
                                <div>
                                    {item.name} - {item.amount} x {item.price}
                                </div>
                                <div>
                                    {item.amount * item.price}
                                </div>
                            </div>
                        ))}
                    </div>
                }

            </div>
        </FormWrapper>
    )
};

export default observer(OrderDetails);