import React, { useState } from 'react';

import { formatQuery, QueryBuilder, RuleGroupType } from 'react-querybuilder';

import 'react-querybuilder/dist/query-builder.scss';
import './QueryRules.scss';
import { observer } from 'mobx-react-lite';

import MenuStore from '../../../../../store/MenuStore';
import CatalogStore from '../../../../../store/CatalogStore';

import { toJS } from 'mobx';
import './QueryRules.scss'

import { SelectBox } from 'devextreme-react/select-box';

import { fields, translations, getOperators } from './QueryConfig'


import QueryItemList from '../QueryItemList';


const QueryRules = ({ addon }) => {

    const initialQuery = addon.condition?.rules ?
        JSON.parse(JSON.stringify(addon.condition).replace(/"rules":null,/gm, ""))
        : {
            combinator: 'and',
            rules: [],
        }

    const [query, setQuery] = useState<RuleGroupType>(initialQuery);

    const onChangeProductItemHandler = (e) => {
        MenuStore.addProductToAddon(e.value, addon.id )
    }

    console.log("QueryBuilder query", query)
    console.log("QueryBuilder  MenuStore.editableMenuAddons", toJS(MenuStore.editableMenuAddons))

    return (
        <div>
            <QueryBuilder
                fields={fields}
                getOperators={getOperators}

                query={query}
                onQueryChange={q => { setQuery(q); MenuStore.updateAddon(q); console.log("q", q) }}
                onAddGroup={() => false}

                translations={translations}
            />

            <QueryItemList items={addon.additionalItems} addonId={addon.id} />

            <SelectBox
                searchEnabled={true}
                onValueChanged={(e) => { onChangeProductItemHandler(e) }}
                placeholder={"Выберите продукт"}
                dataSource={CatalogStore.catalog}
                // items={CatalogStore.catalog.slice(0, 30)}
                displayExpr="name"
            >
            </SelectBox>

            <button onClick={() => { MenuStore.deleteAddon(addon.id) }} className={"addon_delete_btn"}>
                Удалить аддон
            </button>
        </div>
    );
};

export default observer(QueryRules);