import { makeAutoObservable } from "mobx"
import { string } from "yup";

type Tmsg = {
    value: string;
    status: 'ok' | "warning" | "error"
}

class MsgStore {

    msg: Tmsg = {
        value: '',
        status: 'ok',
    }

    isVisible = false;

    msgTimerId: ReturnType<typeof setTimeout>;

    constructor() {
        makeAutoObservable(this);
    }

    setMsg(msg: Tmsg) {
        this.msg = msg
    }

    setMsgIsVisible(isVisible: boolean) {
        this.isVisible = isVisible;
    }

    showMsg(messageObj: Tmsg, timer = 2000) {
        //  MsgStore.showMsg({ value: "Ошибка удаления интеграции", status: "error" }, 10000);
        //  status: "error", "ok", "warning"
        this.setMsg(messageObj);

        if (this.msgTimerId) clearTimeout(this.msgTimerId);
        this.setMsgIsVisible(true);

        this.msgTimerId = setTimeout(() => {
            this.setMsgIsVisible(false);
        }, timer)
    }

}

export default new MsgStore();