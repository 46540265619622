import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import styles from './Showcase.module.scss';

import MenuStore from '../../store/MenuStore';
import PopupStore from '../../store/PopupStore';
import CatalogStore from '../../store/CatalogStore';


import ModifiersFakeGroup from '../ModifiersFakeGroup';
import ItemList from '../ItemList';

import DnD from '../../scripts/DnD';
import { toJS } from 'mobx';
import Item from '../ItemList/Item';
import classNames from 'classnames';
import AdminStore from '../../store/AdminStore';
const dnd = new DnD();

const Showcase = () => {
    const { menuId } = useParams();

    const onChangeMenu = (e) => {
        console.debug("onChangeMenu")
        PopupStore.setActiveForm("menu");
    }

    const onUpdateGroupEditableMenuHandler = (id) => {
        console.debug("onUpdateGroupEditableMenuHandler", id);

        PopupStore.setActiveForm("showcaseGroup");
        const activeItem = MenuStore.getItemFromEditableMenuById(id);
        CatalogStore.setCatalogActiveItem(activeItem);
    }

    const onUpdateProductEditableMenuHandler = (id) => {
        console.debug("onUpdateProductEditableMenuHandler", id);

        PopupStore.setActiveForm("showcaseProduct");
        const activeItem = MenuStore.getItemFromEditableMenuById(id);//взять айтем из editable menu
        CatalogStore.setCatalogActiveItem(activeItem);
    }

    const onDeleteHandlerEditableMenu = (id) => {
        console.debug("onDeleteHandlerEditableMenu", id);

        MenuStore.deepDeleteItemFromEditableMenu(id);
    }


    const dragLeaveHandler = (e) => {
        // на подсветку левой/правой стороны айтема
        dnd.highlightLeaveElementOnSort(e);

        dnd.highlightLeaveHandler(e)
    }


    const dragOverHandler = (e) => {
        dnd.dragOverHandler(e);
        // на подсветку левой/правой стороны айтема
        dnd.highlightOverElementOnSort(e);

        dnd.highlightOverElement(e);
    }

    const dragDropHandler = (e) => {
        dnd.dragDropHandler(e);
        // на подсветку левой/правой стороны айтема
        dnd.highlightDropElementOnSort(e);

        dnd.highlightDropElement(e);
    }

    //showcase
    const onUpdateHandlerModificator = (id) => {
        console.debug("onUpdateHandlerModificator");

        PopupStore.setActiveForm("showcaseModificator");
        const activeItem = MenuStore.getItemFromEditableMenuById(id);
        CatalogStore.setCatalogActiveItem(activeItem);
    }

    console.log("MenuStore.editableMenu[0]", toJS(MenuStore.editableMenu[0]))
    return (
        <>
            {menuId !== "newMenu" && MenuStore.editableMenu?.[0]?.name &&
                <div className={styles.menu_title_wrapper}>
                    <div className={styles.menu_title}>
                        Меню: {MenuStore.editableMenu?.[0]?.name}
                    </div>
                    <div className={styles.menu_input_wrapper}>
                        <button
                            className={styles.update_item_btn}
                            onClick={(e) => onChangeMenu(e)}
                        >
                            <i className="fas fa-pencil-alt"></i>
                        </button>
                    </div>
                </div>
            }

            <div className={"showcase"}
                data-fieldtype='showcase'
                data-itemside={"root"}

                onDragLeave={(e) => dragLeaveHandler(e)}
                onDragOver={(e) => dragOverHandler(e)}
                onDrop={(e) => dragDropHandler(e)}
            >
                {MenuStore.editableMenu?.map((menu) => {
                    return (
                        <div
                            data-id={''}
                            data-type="2"
                            className={classNames(styles.catalog, {[styles.table]: AdminStore.tableView})}
                            key={menu.id}
                        >
                            <ItemList
                                // showModifires={false}
                                key={menu.id}
                                items={menu.items}
                                onUpdateHandlerGroup={onUpdateGroupEditableMenuHandler}
                                onUpdateHandlerProduct={onUpdateProductEditableMenuHandler}
                                onUpdateHandlerModificator={onUpdateHandlerModificator}
                                onDeleteHandler={onDeleteHandlerEditableMenu}
                            />

                            {/* {MenuStore.editableMenu[0].items.filter(item => item.type === 3)
                                .map(item => {
                                    const id = item.id;
                                    return <div key={id} data-id={id} className={styles.product} >
                                        <Item
                                            // showBtn={showBtn}
                                            type={3}
                                            data={item}
                                            id={id}
                                            key={id}
                                            onUpdateHandler={onUpdateHandlerModificator}
                                            onDeleteHandler={onDeleteHandlerEditableMenu}
                                        />
                                    </div>
                                })
                            } */}



                            {/* <ModifiersFakeGroup
                                items={MenuStore.editableMenu[0].items}
                                onUpdateHandlerModificator={onUpdateHandlerModificator}
                                onDeleteHandler={onDeleteHandlerEditableMenu}
                            /> */}

                            <div className={styles.space}>
                            </div>
                        </div>
                    )
                })}


                {/* <ModifiersFakeGroup
                    // key={id}
                    items={MenuStore.editableMenu[0].items.filter(item => item.type === 3)}
                    onUpdateHandlerGroup={onUpdateGroupEditableMenuHandler}
                    onUpdateHandlerProduct={onUpdateProductEditableMenuHandler}
                    onUpdateHandlerModificator={onUpdateHandlerModificator}
                    onDeleteHandler={onDeleteHandlerEditableMenu}
                /> */}
            </div>
        </>
    );
};

export default observer(Showcase);