import React from 'react';

import styles from './HaveNoMenu.module.scss';

const HaveNoMenu = () => {
    return (
        <div className={styles.message_wrapper}>
            <div className={styles.message}>
                Выберете меню
            </div>
        </div>
    );
};

export default HaveNoMenu;