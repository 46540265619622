import { makeAutoObservable, runInAction } from "mobx";

import { ConnectionManager /* getApi */ } from '../http/axios';



import MsgStore from "./MsgStore";


class AuthStore {
    getApi() { console.log("getApi"); return ConnectionManager.GetInstance().GetClient() }
    userName = '';

    isAuth = true;

    loginIntervalId

    constructor() {
        makeAutoObservable(this);
    }

    setIsAuth(isAuth) {
        this.isAuth = isAuth;
    }
    setUserName(userName) {
        this.userName = userName;
    }
    setLoginIntervalId(loginIntervalId) {
        this.loginIntervalId = loginIntervalId
    }

    async login(body) {

        const { login } = body;
        try {
            const res = await ConnectionManager.GetInstance().GetClient().post("/account/login", body);

            runInAction(() => {
                localStorage.setItem("loginTime", JSON.stringify(new Date()));
                this.setIsAuth(true);
                localStorage.setItem('token', res.data);
                this.setUserName(login);
                localStorage.setItem('login', login);
            })

            return res.data;
        } catch (error) {
            MsgStore.showMsg({ value: error.response.data.title, status: "error" }, 6000);
            console.error("ошибка login", error.message)
        }
    }

    async registration(body) {
        try {
            console.log("registration", body)
            const res = await ConnectionManager.GetInstance().GetClient().post("/account/register", body);

            // await this.login(body);
            MsgStore.showMsg({ value: "На вашу почту отправлено письмо с подтверждением регистрации", status: "ok" }, 20000);

            return res.data;
        } catch (error) {
            if ((error.response.data.length === 1)) {
                MsgStore.showMsg({ value: error.response.data.title, status: "warning" }, 10000);
            } else {
                MsgStore.showMsg({ value: error.response.data.title, status: "error" }, 10000);
            }
            console.error("ошибка функции registration", error)
        }
    }

    async forgetPassword(body) {
        try {
            const res = await ConnectionManager.GetInstance().GetClient().post("/account/RecoverPassword", body);

            MsgStore.showMsg({ value: "На вашу почту отправлено письмо с подтверждением сброса пароля", status: "ok" }, 10000);
            return res.data
        } catch (error) {
            MsgStore.showMsg({ value: error.response.data.title, status: "error" });
        }
    }

    async logout() {
        this.setIsAuth(false);
        localStorage.clear();
    }

    TimerStart() {
        clearInterval(this.loginIntervalId)
        function diffDates(day_two, day_one) {
            return (day_two - day_one) / 1000 //seconds
        };

        const loginTime = localStorage.getItem('loginTime');
        const date_one = Date.parse(loginTime);
        if (!date_one) return


        this.setLoginIntervalId(setInterval(() => {
            const date_two = Date.parse(JSON.stringify(new Date()));
            // console.log("INTERVAL!!!", date_two, date_one);
            // console.log(diffDates(date_two, date_one));
            if (diffDates(date_two, date_one) > 3600) {
                // this.logout();
            }
        }, 30000))
    }

    TimerStop() {
        clearInterval(this.loginIntervalId)
    }
}

export default new AuthStore();